import {Injectable} from "@angular/core";
import {LoaderService} from "./loader.service";
import {AppConstants} from "../../app.constants";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs/index";
import {FormData} from "../../form_data";
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class EmployeeEditService {
    constructor( private loader: LoaderService,
                 private http: HttpClient,
                 private authService: AuthService) {}
    /**
     *  To save commitment for add/edit functionalities
     * */
    storeCommitment(formData: object, isEdit: boolean): Observable<any> {
        let url = '';
        if (isEdit) {
            url = AppConstants.baseURL + '/user-commitment/update';
        } else {
            url = AppConstants.baseURL + '/user-commitment/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }
    /**
     * Delete commitment
     * */
    deleteCommitment(detailId: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/user-commitment/delete',
            {detail_id: detailId}, this.authService.getHeaders()).pipe((res) => {
            return res;
        });
    }
    /**
     * fetch commitment
     * */
    fetchCommitments(employeeId: string): Observable<any> {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/user-commitment?id=' + employeeId,
             this.authService.getHeaders()).pipe((res) => {
            return res;
        });
    }
    /**
     * fetch departwise employee joining report
     * */
    employeeJoiningReport(year): Observable<any> {
        this.loader.showLoader();
        let url = AppConstants.baseURL + '/employee-reports/joining-report';
        if (year) {
            url += '?year=' + year;
        }
        return this.http.get(url, this.authService.getHeaders()).pipe((res) => {
           return res;
        });
    }
    /**
     * Date of joining list
     * */
    employeeJoiningList(formData): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee-reports/joining-report-list',
            formData, this.authService.getHeaders()).pipe(res => res);
    }

    modifyCommitment(commitDetails) {
        const commitmentDuration = FormData.getLabels('commitment_duration')['commitment_duration'];
        // console.log(commitmentDuration);
        const commitList = [];
        commitDetails.forEach((detail) => {
            detail['to_month'] = AppConstants.getCommitmentToDate(AppConstants.getInitialDate(new Date(detail['from_month'])),
                detail['user_commitment']).toDate();
            if (detail['user_commitment'] !== '0') {
                detail['duration'] = _.find(commitmentDuration, ['id', detail['user_commitment']])['name'];
                let commitmentString = `${detail['duration']} - From ${AppConstants.modifyDate(detail['from_month'])}
        to ${AppConstants.modifyDate(detail['to_month'])}`;
                if (detail['amount_details']) {
                    commitmentString += `- Bond Amount ${AppConstants.roundNumbers(detail['amount_details']['bond_amount'])}`;
                }
                commitList.push({id: '' + detail['id'],
                    from_date: detail['from_month'],
                    to_month: detail['to_month'],
                    name: commitmentString});
                detail['commitment_string'] = commitmentString;
            } else {
                detail['commitment_string'] = AppConstants.translations['n.a'];
                detail['duration'] = '';
            }
        });
        return [commitDetails, commitList];
    }
    /**
     *  service to change highest education qualification
     *  @param data - contains form data
     * */
    changeHighestQualification(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/education/update-qualification', data, this.authService.getHeaders());
    }
}
