import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acronymWordPipe'
})
export class AcronymWordPipe implements PipeTransform {

  transform(value: string): any {
      var acronym = '';
      var str = value;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      if (matches !== null && matches.length > 1) {
          acronym = matches[0]+''+matches[1];
      } else {
          acronym = str.slice(0, 2);
      }
      return acronym;
  }

}
