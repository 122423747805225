import * as _ from 'lodash';
export class FormData {
    private static labelsData = {
        'n.a': 'N.A',
        'leave_status': [
            { id: 1, name: 'pending', title: 'Pending'},
            { id: 2, name: 'cancel' , title: 'Cancel'}
        ],
        'leave_status_keys': ['pending', 'approve' , 'reject', 'cancel'],
        'leave_statuses': {
            'pending': {
                id: 1,
                name: 'pending',
                title: 'Pending',
                text_class: 'text-leave-pending',
                bg_class: 'bg-leave-pending'
            },
            'approve': {
                id: 2,
                name: 'approve',
                title: 'Approved',
                text_class: 'text-leave-approve',
                bg_class: 'bg-leave-approve'
            },
            'reject': {
                id: 3,
                name: 'reject',
                title: 'Rejected',
                text_class: 'text-leave-rejected',
                bg_class: 'bg-leave-rejected'
            },
            'cancel': {
                id: 4,
                name: 'cancel',
                title: 'Cancel',
                text_class: 'text-leave-cancel',
                bg_class: 'bg-leave-cancel'
            }
        }
        ,
        'defaultLeaveStatus': 'pending',
        'commitment_duration' : [
            {id: '3', name: '3 months'},
            {id: '6', name: '6 months'},
            {id: '9', name: '9 months'},
            {id: '12', name: '1 year'},
            {id: '15', name: '1 year & 3 months'},
            {id: '18', name: '1 year & 6 months'},
            {id: '21', name: '1 year & 9 months'},
            {id: '24', name: '2 years'},
            {id: '36', name: '3 years'},
        ],
        'document_types': [
            { id: 1, name: 'Identification', title: 'Identification'},
            { id: 2, name: 'Address Proof' , title: 'Proof Of Address'},
            { id: 3, name: 'Education' , title: 'Education'},
            { id: 4, name: 'Work' , title: 'Work'}
        ],
        'increment_after': [
            { id: 3, name: '3 months'},
            { id: 6, name: '6 months' },
            { id: 12, name: '1 year' },
            { id: 18, name: '18 months'}
        ],
        'commitment_type': [
            { id: 1, name: 'Under Commitment'},
            { id: 2, name: 'Extend Commitment'},
            { id: 3, name: 'New Commitment'},
            { id: 4, name: 'No Commitment'},
        ],
        'filters': {
            year: 'Year',
            role: 'Role',
            'technology': 'Technology',
            'department': 'Department'
        },
        'user' : {
            'firstName': 'First Name',
            'lastName': 'Last Name',
            'middleName': 'Middle Name',
            'dob': 'Date Of Birth',
            'gender': 'Gender',
            'marital': 'Marital Status',
            'company': 'Company',
            'department': 'Department',
            'role': 'Role',
            'email': 'Email Address',
            'password':  'Password',
            'cpassword': 'Confirm Password',
            'recruited_by': 'Recruiter',
            'candidate_id': 'Candidate',
            'm': 'Male',
            'f': 'Female',
            'married': 'Married',
            'unmarried': 'Unmarried',
            'user_type': 'User Type',
            'technology': 'Technology',
            'nickName': 'Display Name',
            'personalEmail': 'Personal Email Address',
            'mobileNo': 'Mobile Number',
            'workMobile': 'Work Phone',
            'phoneNo': 'Residense Phone',
            'skypeId': 'Skype Id',
            'address': 'Address',
            'city': 'City',
            'state': 'State',
            'country': 'Country',
            'pincode': 'Pincode'
        },
        'employment' : {
            'joining_date': 'Joining Date',
            'joined_as': 'Joined As',
            'relevant_exp': 'Relevant Experience',
            'office': 'Office Location',
            'joining_bonus': 'Joining Bonus',
            'bonus_note': 'Bonus Note',
            'special_note': 'Special Note',
            'is_bonus': 'Is Bonus',
            'bonus': 'Bonus Amount',
            'y': 'Yes',
            'n': 'No',
            'probation_date': 'Probation Date',
            'probation_period': 'Probation Period',
            'designation' : 'Designation'
        },
        'salary_details' : {
            'from_date' : 'Increment From',
            'next_inc': 'Next Increment After',
            'next_inc_date': 'Next Increment Date',
            'gross_salary': 'Gross Salary',
            'remarks': 'Remarks',
            'commit_id': 'Commitment',
            'commit_list': 'Commitment List'
        },
        'commitment': {
            'from_date': 'Commitment From',
            'bond_period': 'Commitment Period',
            'amount_id': 'Bond Amount'
        },
        'variable': {
            'variable_from_date': 'Variable From',
            'duration': 'Duration (Months)',
            'amount': 'Approved Amount'
        },
        'otherInfo': {
            'adharNo': 'Adhar No',
            'uanNo': 'UAN No',
            'panNo': 'PAN No',
            'bankId': 'Bank ID',
            'bankName': 'Bank Name',
            'branchName': 'Branch Name',
            'bankAccNo': 'Bank Account No',
            'bankNote': 'Bank Note',
            'blood_group': 'Blood Group'
        },
        'payments': {
            'technology_id': 'Technology',
            'userid': 'User',
            'payment_from': 'From Date',
            'to_months': 'To Months',
            'payment_to': 'To Date',
            'payment_typeid': 'Payment Type',
            'amount':  'Amount',
            'paid_by':  'Paid By',
            'remarks': 'Remarks'
        },
        'education': {
            'degree': 'Degree / Qualification',
            'other_degree': 'Other Degree',
            'specialization': 'Specilization',
            'other_specialization': 'Other Specialization',
            'university': 'University / Board',
            'other_university': 'Other University',
            'institute': 'Institute Name',
            'course_type': 'Type of Course',
            'passing_year': 'Year of Passing',
            'percentage':  '% / Percentile'
        },
        'teamLogs': {
            'team_lead': 'Team Leader Name',
            'from_date': 'From Date',
            'to_date': 'To Date',
            'current_TL': 'Current Team Lead',
            'current_Tech': 'Current Technology',
            'current_role': 'Current Designation'
        },
        'work_experience': {
            'company_website': 'Company Website',
            'from_date': 'Worked From',
            'to_date': 'Worked To',
            'total_exp': 'Total Experience',
        'annual_ctc': 'Annual CTC',
        // 'job_type': 'Job Type',
        // 'employee_id': 'Employee ID',
        'leaving_reason': 'Reason for Leaving'
        },
        'document': {
            'doc_type': 'Document Type',
            'issued_on': 'Issued On',
            'remark': 'Remark',
            'document1': 'Document 1',
            'document2': 'Document 2',
            'document3': 'Document 3',
            'document4': 'Document 4',
            'document5': 'Document 5'
        }
    };
    public  static getLabels(table) {
        let response = {};
        if (_.isArray(table)) {
            table.forEach((tName) => {
                response[tName] = this.labelsData[tName];
            });
        } else {
          response[table] = this.labelsData[table];
        }
        return response;
    }
    append(arg0: string, fileData: File, name: string) {
        throw new Error("Method not implemented.");
    }
}
