import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../shared/services/auth.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertService} from "../shared/services/alert.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,
                private alertService: AlertService,
                private router: Router,
                private modalService: NgbModal) { }
    getAlertOwn() {
        this.alertService.getAlert().subscribe(res => {
            this.alertService.alertBar(res);
        });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            let error = err.error.message || err.statusText;
            if (err.status === 401) {
                this.authenticationService.authenticated = false;
                // this.authenticationService.signout();
                error = err.error.error;
                this.modalService.dismissAll();
                this.router.navigateByUrl('/sessions/signin');
            }
            if (err.status === 0) {
                error = 'Invalid request';
            }
            if (err.status === 400) {
                if (err.error.data) {
                   error = err.error.data;
                } else {
                    error = err.error.error;
                }
            }
            if (err.status === 422) {
                if (err.error.data) {
                    error = err.error.data;
                } else {
                    error = err.error.error;
                }
            }
            if (err.status === 404) {
                error = err.error.error;
            }
            console.log(err);
            return throwError(error);
        }));
    }
}
