import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    // disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
    visible?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
    visible?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService extends AuthService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    defaultMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Main Dashboard',
            type: 'link',
            icon: 'i-Dashboard',
            state: '/dashboard',
            visible: this.checkNavigationAccess('DashboardC_changeEmployeeCount')
        },
        {
            name: 'Employee',
            description: 'Employee module',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                {
                    icon: 'i-Dashboard',
                    name: 'Dashboard',
                    state: '/manage/employee/dashboard',
                    type: 'link',
                    visible: this.checkNavigationAccess(
                        ['UserContro_userDashboard', 'Department_departmentList', 'UserContro_departmentWiseRole'], 'AND') },
                {
                    icon: 'i-Christmas-Candle',
                    name: 'Birthday',
                    state: '/manage/employee/birthday',
                    type: 'link',
                    // visible: this.checkNavigationAccess()
                },
                {
                    icon: 'i-Add-User',
                    name: 'Add Employee',
                    state: '/manage/employee/add',
                    type: 'link',
                    visible: this.checkNavigationAccess(
                        ['UserContro_getRecruiter', 'CompanyCon_companyList', 'Department_departmentList',
                            'UserContro_departmentWiseRoles', 'UserContro_departmentWiseTech', 'RoleContro_roleList',
                            'UsertypeCo_usertypeList',
                            'OfficeLoca_officeLocationList', 'ResourceCa_categoryList', 'CandidateC_getCandidatesOfRecruiter',
                            'CandidateC_fetchCandidateById', 'UserContro_emailExist', 'UserContro_store'], 'AND') },
                {
                    icon: 'i-Check',
                    name: 'List',
                    state: '/manage/employee/list',
                    type: 'link',
                    visible: this.checkNavigationAccess('UserContro_index') },
                { icon: 'i-Calendar',
                    name: 'Attendance',
                    state: '/manage/employee/attendancelist',
                    type: 'link',
                    visible: this.checkNavigationAccess('UserContro_getAttendance') },
                { icon: 'i-Clock',
                    name: 'Night Shift',
                    state: '/manage/employee/night-shift',
                    type: 'link',
                    visible: this.checkNavigationAccess('UserContro_timezoneList') },
                { icon: 'i-Receipt',
                    name: 'Joining Report',
                    state: '/manage/employee/joining-report',
                    type: 'link' ,
                    visible: this.checkNavigationAccess('EmployeeRe_joinedReport')},
                { icon: 'i-Receipt',
                    name: 'Joining List',
                    state: '/manage/employee/joining-list',
                    type: 'link',
                    visible: this.checkNavigationAccess('EmployeeRe_joiningReportList') },
                { icon: 'i-Administrator',
                    name: 'Profile',
                    state: '/manage/employee/profile/' + this.getUser()['local_id'],
                    type: 'link',
                    visible: this.checkNavigationAccess(['UserContro_show', 'LeaveAppli_show',
                        'TeamContro_fetchEmployeeteamLeaders', 'Technology_fetchEmployeeTechnologyLogs',
                        'Designatio_fetchEmployeeDesignationLogs', 'UserDocume_getDocuments'], 'AND')
                },
                { icon: 'i-Bar-Chart',
                    name: 'Left Report',
                    state: '/manage/employee/left-report',
                    type: 'link',
                    visible: this.checkNavigationAccess('EmployeeRe_leftReport')
                },
                { icon: 'i-Credit-Card',
                    name: 'Salary Account',
                    state: '/manage/employee/salary-account',
                    type: 'link',
                    visible: this.checkNavigationAccess('UserContro_accountData')
                },
                { icon: 'i-Check',
                    name: 'Employee Types',
                    state: '/manage/employee-types',
                    type: 'link',
                    visible: this.checkNavigationAccess('UsertypeCo_index')
                }
            ],
            visible: this.checkNavigationAccess(['UserContro_userDashboard',
                'UserContro_store', 'UserContro_index', 'UsertypeCo_index'])
        },
        {
            name: 'Leave Management',
            description: 'Leave Management',
            type: 'link',
            icon: 'i-Ticket',
            state: '/manage/leave-management/requests/grid',
            visible: this.checkNavigationAccess(['LeaveAppli_index'])
        },
        {
            name: 'Service Requests',
            description: 'Service Requests',
            type: 'dropDown',
            icon: 'i-Ticket',
            sub: [
                { icon: 'i-Check',
                    name: 'Increment Requests',
                    state: '/manage/increment-requests/list',
                    type: 'link',
                    visible: this.checkNavigationAccess('EmployeeIn_index')
                },
                { icon: 'i-Check',
                    name: 'Variable Requests',
                    state: '/manage/variable-requests',
                    type: 'link',
                    visible: this.checkNavigationAccess('VariableRe_index')
                }
            ],
            visible: this.checkNavigationAccess(['EmployeeIn_index', 'VariableRe_index'])
        },
        // {
        //     name: 'Tickets',
        //     description: 'Tickets',
        //     type: 'link',
        //     icon: 'i-Ticket',
        //     state: '/manage/tickets/list'
        // },
        {
            name: 'Tickets',
            description: 'Tickets',
            type: 'dropDown',
            icon: 'i-Ticket',
            sub: [
                { icon: 'i-Dashboard',
                    name: 'Dashboard',
                    state: '/manage/tickets/dashboard',
                    type: 'link',
                    visible: this.checkNavigationAccess('TicketCont_ticketDashboard')
                },
                { icon: 'i-Check',
                    name: 'List',
                    state: '/manage/tickets/list',
                    type: 'link',
                    visible: this.checkNavigationAccess('TicketCont_index')
                }
            ],
            visible: this.checkNavigationAccess(['TicketCont_ticketDashboard', 'TicketCont_index'])
        },
        {
            name: 'Attendance Query',
            description: 'Attendance Query',
            type: 'dropDown',
            icon: 'i-Ticket',
            sub: [
                { icon: 'i-Dashboard',
                    name: 'Dashboard',
                    state: '/manage/attendance-query/dashboard',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_attendanceQueryDashboard'])
                },
                { icon: 'i-Bar-Chart',
                    name: 'Report',
                    state: '/manage/attendance-query/report',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_attendanceQueryList'])
                }
            ],
            visible: this.checkNavigationAccess(['ReportCont_attendanceQueryList', 'ReportCont_attendanceQueryDashboard'])
        },
        {
            name: 'Left Employee',
            description: 'Left Employee',
            type: 'dropDown',
            icon: 'i-Left1',
            sub: [
                { icon: 'i-Dashboard',
                    name: 'Dashboard',
                    state: '/manage/left-employee/dashboard',
                    type: 'link',
                    visible: this.checkNavigationAccess(['LeftEmploy_dashboard'])
                },
                { icon: 'i-Check',
                    name: 'List',
                    state: '/manage/left-employee/list',
                    type: 'link',
                    visible: this.checkNavigationAccess(['LeftEmploy_leftEmpList'])
                },
                { icon: 'i-Add',
                    name: 'Add',
                    state: '/manage/left-employee/add',
                    type: 'link',
                    visible: this.checkNavigationAccess(['LeftEmploy_add'])
                }
            ],
            visible: this.checkNavigationAccess(['LeftEmploy_dashboard', 'LeftEmploy_leftEmpList', 'LeftEmploy_add'])
        },
        {
            name: 'Reports',
            description: 'Reports',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Windows-Microsoft',
                    name: 'Technology',
                    state: '/manage/reports/technology',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_technologyList',
                                                        'ReportCont_technologyEmp',
                                                        'ReportCont_updateTechnology'])
                },
                { icon: 'i-Conference',
                    name: 'Team Lead',
                    state: '/manage/reports/teamlead',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_teamleadList', 'ReportCont_teamEmployee',
                                                        'ReportCont_updateTeam', 'ReportCont_removeTeam'])
                },
                { icon: 'i-Telephone',
                    name: 'Employee Contact',
                    state: '/manage/reports/contact',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_contactList', 'ReportCont_updateContactDetails'])
                },
                { icon: 'i-Bar-Chart',
                    name: 'Work Experience',
                    state: '/manage/reports/work-experience',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_WorkExperienceList'])
                },
                { icon: 'i-Book',
                    name: 'Education',
                    state: '/manage/reports/education',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_educationList', 'ReportCont_getEducationDetail'])
                },
                { icon: 'i-Building',
                    name: 'Office Location',
                    state: '/manage/reports/office-location',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_officeLocationList',
                                                        'ReportCont_locationEmpList',
                                                        'ReportCont_updateOfficeLocationDetails'])
                },
                { icon: 'i-Building',
                    name: 'Company',
                    state: '/manage/reports/company',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_companyList',
                                                        'ReportCont_companyEmpList',
                                                        'ReportCont_updateCompanyDetails'])
                },
                { icon: 'i-Financial',
                    name: 'Salary',
                    state: '/manage/reports/salary',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_salaryOfferedList', 'ReportCont_updateSalaryDetails'])
                },
                { icon: 'i-Gear',
                    name: 'Service',
                    state: '/manage/reports/service',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_serviceList', 'ReportCont_updateService'])
                },
                { icon: 'i-Receipt-4',
                    name: 'Personal Details',
                    state: '/manage/reports/personal-details',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_personalData', 'ReportCont_updatePersonalDetails'])
                },
                // { icon: 'i-Sand-watch-2', name: 'Probation Period', state: '/manage/reports/probation-period', type: 'link' },
                { icon: 'i-Sand-watch',
                    name: 'Increment Due',
                    state: '/manage/reports/increment-due',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_incrementDueList'])
                },
                { icon: 'i-Tag-4',
                    name: 'Upcoming Joinees',
                    state: '/manage/reports/upcoming-joinees',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_joineesList'])
                },
                { icon: 'i-Fingerprint-2',
                    name: 'Bio Metric',
                    state: '/manage/reports/bio-metric',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_biometricData',
                                                        'ReportCont_updateBiometricDetails'])
                },
                { icon: 'i-Mail',
                    name: 'To and Cc',
                    state: '/manage/reports/to-and-cc',
                    type: 'link',
                    visible: this.checkNavigationAccess(['ReportCont_toAndCcData', 'ReportCont_updateToAndCcDetails'])
                }
            ],
            visible: this.checkNavigationAccess(['ReportCont_technologyList', 'ReportCont_teamleadList',
            'ReportCont_contactList', 'ReportCont_joineesList', 'ReportCont_biometricData', 'ReportCont_toAndCcData'])
        },
        // {
        //     name: 'Payments',
        //     description: 'Payments',
        //     type: 'link',
        //     icon: 'i-Money-Bag',
        //     state: '/manage/payments'
        // },
        {
            name: 'Payments',
            description: 'Payments',
            type: 'dropDown',
            icon: 'i-Money-Bag',
            sub: [
                { icon: 'i-Dashboard',
                    name: 'Dashboard',
                    state: '/manage/payments/dashboard',
                    type: 'link',
                    visible: this.checkNavigationAccess(['PaymentReq_dashboard'])
                },
                { icon: 'i-Check',
                    name: 'List',
                    state: '/manage/payments/list',
                    type: 'link',
                    visible: this.checkNavigationAccess(['PaymentReq_paymentTypeList', 'PaymentReq_index',
                                                        'PaymentReq_store', 'PaymentReq_update',
                                                        'PaymentReq_show', 'PaymentReq_delete'])
                }
            ],
            visible: this.checkNavigationAccess(['PaymentReq_dashboard', 'PaymentReq_index'])
        },
        {
            name: 'Documents',
            description: 'Documents',
            type: 'link',
            icon: 'i-Files',
            state: '/manage/documents',
            visible: this.checkNavigationAccess(['UserDocume_fetchAllDocuments', 'UserDocume_downloadFile',
                                                'UserDocume_storeDocument', 'UserDocume_show',
                                                'UserDocume_getDocuments', 'UserDocume_updateDocument',
                                                'UserDocume_deleteDocument'])
        },
        {
            name: 'ACL',
            description: 'ACL',
            type: 'dropDown',
            icon: 'i-Lock-User',
            sub: [
                { icon: 'i-Check',
                    name: 'Roles',
                    state: '/manage/acl/acl-roles',
                    type: 'link',
                    visible: this.checkNavigationAccess('AclRoleCon_index')
                },
                { icon: 'i-Check',
                    name: 'Manage Role Permissions',
                    state: '/manage/acl/assign-permissions',
                    type: 'link',
                    visible: this.checkNavigationAccess('AclRoleCon_store')
                },
                { icon: 'i-Check',
                    name: 'Permissions',
                    state: '/manage/acl/acl-controllers',
                    type: 'link',
                    visible: this.checkNavigationAccess('AclControl_index')
                },
                { icon: 'i-Check',
                    name: 'Users',
                    state: '/manage/acl/user-roles',
                    type: 'link',
                    visible: this.checkNavigationAccess('AclUserRol_index')
                }
            ],
            visible: this.checkNavigationAccess(['AclRoleCon_index', 'AclRoleCon_store', 'AclControl_index', 'AclUserRol_index'])
        },
        // {
        //     name: 'Sessions',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-Administrator',
        //     sub: [
        //         // { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
        //         { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
        //         // { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
        //     ]
        // },
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
