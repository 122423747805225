import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppConstants} from '../../app.constants';
import {map} from 'rxjs/operators';
import {FormData} from "../../form_data";
import {tap} from "rxjs/internal/operators";
import {of} from "rxjs/index";

@Injectable({
    providedIn: 'root'
})
export class LeaveMgmtService {
    constructor(private http: HttpClient,
                private authService: AuthService) {}
    fetchLeaveRequests(postData) {
        return this.http.post(AppConstants.baseURL + '/leaves', postData, this.authService.getHeaders()).pipe(map((res) => {
            if (res['success']) {
               return res['data'];
            } else {
                return res;
            }
        }));
    }
    fetchLeaveApplication(applicationId) {
        return this.http.post(AppConstants.baseURL + '/leaves/fetch_by_id',
            {requestId: applicationId}, this.authService.getHeaders()).pipe(map((res) => {
            if (res['success']) {
                return res['data'];
            } else {
                return res;
            }
        }));
    }
    saveComment(postData) {
        return this.http.post(AppConstants.baseURL + '/leaves/add_comment', postData, this.authService.getHeaders());
    }
    deleteLeaveApplication(requestId) {
        return this.http.post(AppConstants.baseURL + '/leaves/delete', {leaveId: requestId},
            this.authService.getHeaders()).pipe((res) => res);
    }
    getUsersList(search = '') {
        const formData = {};
        formData['is_email'] = true;
        formData['email'] = search ;
        // console.log(formData);
        return this.http.post(AppConstants.baseURL + '/employee/droplist', formData,
            this.authService.getHeaders()).pipe(tap((res) => res['data']), map((res) => {
            return res['data'];
        }));
        // return of([
        //     {'email': 'hemaxi.lade@neosofttech.com'}
        // ]);
    }
    getFilters(activeStatus) {
        let postData = {};
        if (activeStatus != null) {
           postData['status'] = activeStatus;
        }
        return this.http.post(AppConstants.baseURL + '/leaves/filter', postData, this.authService.getHeaders()).pipe((res) => res);
    }
    forwardApplication(postData) {
        return this.http.post(AppConstants.baseURL + '/leaves/forward_leave_application', postData, this.authService.getHeaders());
    }
    getPlannedLeaves(postData) {
        return this.http.post(AppConstants.baseURL + '/leaves/planned', {}, this.authService.getHeaders());
    }
}
