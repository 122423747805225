
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AppConstants} from "../../../app.constants";

@Component({
  selector: 'app-scroll-pagination',
  templateUrl: './scroll-pagination.component.html',
  styleUrls: ['./scroll-pagination.component.scss']
})
export class ScrollPaginationComponent implements OnInit {

    @Input() paginationData = [];
    @Input() entityList = [];
    @Input() page;
    @Input() pageSize;
    paginationList = AppConstants.paginationList();
    @Output() perPageChange = new EventEmitter<any>();
    ngOnInit() {
        //
    }
    onPerPage() {
        this.perPageChange.emit(this.pageSize);
    }
}
