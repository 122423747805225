import { Component, OnInit, Input } from '@angular/core';
import {AlertService} from '../../../shared/services/alert.service';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-skill-logs',
  templateUrl: './skill-logs.component.html',
  styleUrls: ['./skill-logs.component.scss'],
  providers: [NgbRatingConfig]
})
export class SkillLogsComponent implements OnInit {
  technologyId: string;
  stars: number[] = [1, 2, 3];
  selectedValue: number;

  constructor(
    private alertService: AlertService,
    config: NgbRatingConfig
  ) {
    // customize default values of rating3 used by this component tree
    config.max = 3;
    config.readonly = true;
  }
  @Input() skillTypeKeys;
  @Input('skillsLogs') logsData: { [key: string]: any; };
  @Input() techId;

  skillData: { [key: string]: any; } = {};
  selected :any;

  ngOnInit() {
    this.skillData = this.logsData[this.skillTypeKeys[0]][this.skillTypeKeys[0]];
    // console.log(this.skillData);
    this.selected = this.skillTypeKeys[0];
    this.subscribeAlert();
  }

  /**
   * Fetch skills details of employee through skill-type
   */
  getSkillsDetails(event, skillType) {
    this.selected = skillType;
    this.skillData = this.logsData[skillType][skillType];
    // console.log(this.skillData);
  }

  /**
   * Subscribe to alert subject to view toastr
   */
  subscribeAlert() {
    this.alertService.getAlert().subscribe((res) => {
        this.alertService.alertBar(res);
    });
  }

}
