import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appCopyClipboard]'
})
export class CopyClipboardDirective {
    @Input() appCopyClipboard: string;
    @Output('copied')
    public copied: EventEmitter<string> = new EventEmitter<string>();
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {

        event.preventDefault();
        if (!this.appCopyClipboard)
            return;

        let listener = (e: ClipboardEvent) => {
            let clipboard = e.clipboardData || window['clipboardData'];
            clipboard.setData('text', this.appCopyClipboard.toString());
            e.preventDefault();

            this.copied.emit(this.appCopyClipboard);
        };

        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
    }
  constructor() { }

}
