import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
    @Input() totalItems;
    @Input() pageN;
    @Output() pageChangeRequest = new EventEmitter();
    ngOnInit() {
        //
    }
}
