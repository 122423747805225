import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'monthsToYearMonth'
})
export class MonthsToYearMonthsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return moment.duration(value, 'seconds').format('Y [years]: M [months]: D [days]');
  }

}