import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'dateFormat',
    pure: false
})
export class DateFormatPipe implements PipeTransform {
    transform(value, args?: any): any {
        if (value == null || value === '' || value !== value) {
            return null;
        }
        if (typeof value === 'string') {
            value = value.trim();
        }
        if (args === 'mY') {
            return moment(value).format('MMM, YYYY');
        }
        return moment(value).format('Do MMM, YYYY');
    }
}
