import { Injectable } from '@angular/core';
import {LoaderService} from './loader.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {LocalStoreService} from './local-store.service';
import {Observable} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

    constructor(private loader: LoaderService,
                private http: HttpClient,
                private authService: AuthService,
                private store: LocalStoreService) {
    }

    /**
     * Fetch document types list
     * */
    fetchDocumentTypes() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/doc_type/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Save user's documents
     */
    saveUserDocuments(formData, isEdit: boolean) {
        this.loader.showLoader();
        let url = '';
        if (isEdit) {
            url = AppConstants.baseURL + '/employee/documents/update';
        } else {
            url = AppConstants.baseURL + '/employee/documents/store';
        }
        return this.http.post(url, formData, this.authService.getfileHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    /**
     * Delete Document Detail
     * */
    deleteUserDocumentDetail(document_id: string): Observable<any> {
        return this.http.post(AppConstants.baseURL + '/employee/documents/delete',
            {document_id: document_id}, this.authService.getHeaders()).pipe((res) => {
            return res;
        });
    }

    /**
     * API call to Fetch users document
     */
    getUserDocument(documentId) {
        return this.http.post(AppConstants.baseURL + '/employee/documents/show',
            {document_id: documentId}, this.authService.getHeaders()).pipe((res) => res);
    }

    /**
     * API call to Fetch users documents details
     */
    fetchUserDocuments(userId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/documents/get/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch all uploaded documents
     */
    fetchAllUserDocuments(formData) {
        return this.http.post(AppConstants.baseURL + '/documents', formData, this.authService.getHeaders())
            .pipe((res) => {
                return res;
            });
    }

    /**
     * API call to Download file
     */
    downloadFile(docData) {
        const param = {'file_path': docData['doc_path'], 'employee_id': docData['employee_id']};
        return this.http.get(AppConstants.baseURL + '/documents/download', this.authService.setFileHeaders(param));
    }
    /**
     * API call to Download file
     */
    downloadCandidateFile(docData) {
        const param = {'file_path': docData['file_path'], profile: docData['profile']};
        return this.http.get(AppConstants.baseURL + '/candidates/' + docData.candidate_id + '/fetch-document'
            , this.authService.setFileHeaders(param));
    }
    /**
     *  Get candidate documents list
     * */
    fetchCandidateDocuments(candidateId) {
        return this.http.get(AppConstants.baseURL + '/candidates/' + candidateId + '/documents', this.authService.getHeaders());
    }
    /**
     *  Api to upload document from careers to hr
     *  @params image string filename, data object form data object
     * */
    uploadProfileCareers(image, data) {
        const formData = {
            image,
            employeeId: data['employeeId'],
        };
        return this.http.post(AppConstants.baseURL + '/employee-fetch-upload', formData, this.authService.getHeaders())
            .pipe(retry(2));
    }
}
