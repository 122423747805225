import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appHighlight]'
})
export class HighlightDirective {

    constructor(private el: ElementRef) { }

    @Input('appHighlight') highlightClass;

    @HostListener('mouseenter') onMouseEnter() {
        this.highlight(this.highlightClass);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.highlight(null);
    }

    private highlight(color: string) {
        if (color != null)
            this.el.nativeElement.classList.add(this.highlightClass);
        else
            this.el.nativeElement.classList.remove(this.highlightClass);
        // if ( color != null) {
        //     const backgroundColor = color ;
        //     const textColor = 'white';
        // } else {
        //     const backgroundColor = 'white' ;
        //     const textColor = color;
        // }
        // this.el.nativeElement.style.backgroundColor = textColor;
        // this.el.nativeElement.style.color = backgroundColor;
    }
}