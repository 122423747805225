import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from './search/search.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutsModule } from './layouts/layouts.module';
import {SalaryViewComponent} from './salary-view/salary-view.component';
import {NoRecordsComponent} from './no-records/no-records.component';
import { SkillLogsComponent } from './skill-logs/skill-logs.component';
import {ConfirmBoxComponent} from './confirm-box/confirm-box.component';
import {ViewDocumentsComponent} from './view-documents/view-documents.component';
import {PaymentsFormComponent} from './payments-form/payments-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SalaryFormComponent} from './salary-form/salary-form.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {PaginationComponent} from './pagination/pagination.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ScrollPaginationComponent} from './scroll-pagination/scroll-pagination.component';
import { AutoSearchComponent } from './auto-search/auto-search.component';
import {CopyDocumentsComponent} from './copy-documents/copy-documents.component';

const components = [
    BtnLoadingComponent,
    FeatherIconComponent,
    SalaryViewComponent,
    NoRecordsComponent,
    SkillLogsComponent,
    ConfirmBoxComponent,
    PaymentsFormComponent,
    SalaryFormComponent,
    ViewDocumentsComponent,
    PaginationComponent,
    ScrollPaginationComponent,
    AutoSearchComponent,
    CopyDocumentsComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SearchModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    NgxDocViewerModule,
    NgbModule,
      PaginationModule.forRoot(),
      NgSelectModule,
      BsDatepickerModule.forRoot(),
      ReactiveFormsModule
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule { }
