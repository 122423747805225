import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class AlertService {
    private alertMessage = new Subject();
    private keepAfterRouteChange = false;
    private previousMessage;
    public loading = false;
  constructor(private toastr: ToastrService,
              private router: Router) {
      router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
              if (this.keepAfterRouteChange) {
                  // only keep for a single route change
                  this.keepAfterRouteChange = false;
              } else {
                  // this.keepAfterRouteChange = true;
                  // clear alert messages
                  // this.clear();
              }
          }
      });
  }
    success(message: string, afterChange: boolean) {
        this.alert({
            message: message,
            afterChange: afterChange,
            type: 'Success'
        });
    }

    error(message: string, afterChange: boolean) {
        this.alert({
            message: message,
            afterChange: afterChange,
            type: 'Error'
        });
    }

    info(message: string, afterChange: boolean) {
        this.alert({
            message: message,
            afterChange: afterChange,
            type: 'Info'
        });
    }

    warn(message: string, afterChange: boolean) {
        this.alert({
            message: message,
            afterChange: afterChange,
            type: 'Warning'
        });
    }
    // main alert method
    alert(alert) {
        this.keepAfterRouteChange = alert.afterChange;
        // console.log(this.previousMessage, alert);
        this.previousMessage =  alert;
        this.alertMessage.next(alert);
    }
    getAlert() {
      return this.alertMessage;
    }
    // clear alerts
    clear() {
        this.alertMessage.observers = [];
    }
    alertBar(res) {
        if (res) {
            window.scrollTo(0, 0);
            switch (res['type']) {
                case 'Success':
                    this.toastr.success(res['message'], 'Success');
                    break;
                case 'Warning':
                    this.toastr.warning(res['message'], 'Warning');
                    break;
                case 'Info':
                    this.toastr.info(res['message'], 'Info');
                    break;
                case 'Error':
                    this.toastr.error(res['message'], 'Error');
                    break;
                default:
                    break;
            }
            // this.alertMessage.observers = [];
        }
    }
}
