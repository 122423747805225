import {
    AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnChanges, OnInit,
    ViewChild
} from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import {AlertService} from '../../../services/alert.service';
import {LoaderService} from '../../../services/loader.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-admin-layout-sidebar-large',
  templateUrl: './admin-layout-sidebar-large.component.html',
  styleUrls: ['./admin-layout-sidebar-large.component.scss']
})
export class AdminLayoutSidebarLargeComponent implements OnInit{

    moduleLoading: boolean;
    @ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;
    constructor(
      public navService: NavigationService,
      public searchService: SearchService,
      private router: Router,
      private loader: LoaderService,
      private ref: ChangeDetectorRef
    ) {
        // ref.detach();
        // setInterval(() => {this.ref.detectChanges(); }, 5);
    }
    loading = false;
    loadingRef = false;
    loaderSubscription;
    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.moduleLoading = true;
        }
        if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
          this.moduleLoading = false;
        }
      });
        this.loader.getLoader().pipe(delay(0)).subscribe((res) => {
            // if ( res != this.loading) {
            //     this.loading = res;
            // }
            if (res === true) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        }, (error) => {
            console.log(error);
        });
    }
}
