import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import * as saveAs from 'file-saver';
import {AppConstants} from '../../../app.constants';
@Component({
  selector: 'app-view-documents',
  templateUrl: './view-documents.component.html',
  styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent implements OnInit {

  constructor(public modal: NgbActiveModal,
              private documentService: DocumentsService) { }

  @Input() heading;
  @Input() docsData;
  @Output() okEvent = new EventEmitter();
  getDownloadFileName = AppConstants.getDownloadFileName;
  ngOnInit() {
    console.log(this.docsData);
  }
  /**
   *  Download document - single doc path file inside document entry
   * */
  downloadDocument(docData, rowIndex) {
    this.docsData[rowIndex].downloading = true;
    this.documentService.downloadFile(docData).subscribe((res: any) => {
        const blob = new Blob([res]);
        this.docsData[rowIndex].downloading = false;
        if (docData['is_uploaded'] === '0') {
            saveAs(blob, AppConstants.getDownloadFileName(docData.employee_name, docData.doc_type, docData.doc_path, true ));
        } else {
            saveAs(blob, AppConstants.getDownloadFileName(docData.employee_name, docData.doc_type, docData.doc_path ));
        }
    });
  }
  /**
   *  okEvent Emitter - click event handler
   * */
  okHandler(event) {
    this.okEvent.emit(true);
  }

}
