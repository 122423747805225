import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDatepickerConfig, BsDatepickerViewMode} from 'ngx-bootstrap/datepicker';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmployeeService} from '../../services/employee.service';
import {AlertService} from '../../services/alert.service';
import {LoaderService} from '../../services/loader.service';
import {AppConstants} from '../../../app.constants';
import {FormData} from '../../../form_data';
import * as _ from 'lodash';
import {EmployeeEditService} from '../../services/employee-edit.service';
import {ServerValidationService} from "../../services/server-validation.service";
@Component({
  selector: 'app-salary-form',
  templateUrl: './salary-form.component.html',
  styleUrls: ['./salary-form.component.scss']
})
export class SalaryFormComponent implements OnInit {
    // Date picker configs
    bsConfig: Partial<BsDatepickerConfig>;
    minMode: BsDatepickerViewMode = 'month';
    loading = false; // on submit
    formTitle = 'Add New';
    salaryParameters = []; // salary paremeters list used as form inputs
    bondAmountList = []; // bond amount dropdown
    formResetObject = {'commit_id': null};
    isEdit = false;
    grossSalary = 0; // gross salary
    salarySubmitted = false; // on submit
    server_validation = {};
    incrementToDate; // increment to date
    appConstants = AppConstants.translations;
    commitmentFromDate; // for new commitment type it should be greater than equal to increment from date
    commitmentList = []; // for commitment dropdown
    commitDetails = []; // response from api
    showCommitList = false; // flags used to show hide inputs
    showBondAmount = false;
    showBondPeriod = false;
    initalCommitType = null;
    finalCommitType = null;
    trans = FormData.getLabels(['salary_details']);
    disableFromDate = false;
    // constants used in form
    incrementAfterList = FormData.getLabels('increment_after')['increment_after'];
    commitmentTypeList = FormData.getLabels('commitment_type')['commitment_type'];
    commitmentDuration = FormData.getLabels('commitment_duration')['commitment_duration'];
    @Input() incrementDetail ; // increment request detail passed
    @Input() salaryId = ''; // passed when edit
    @Input() employeeId;
    @Input() incrementFromDate; // will be greater than last two date or increment request from date
    @Input() joiningDate;
    @Input() salaryDetail = []; // will be given in edit
    @Output() salaryChanged = new EventEmitter(); // emit once saved
    minIncrementFromDate: Date = this.joiningDate;
  constructor(private fb: FormBuilder,
              private employeeService: EmployeeService,
              private employeeEditService: EmployeeEditService,
              private alertService: AlertService,
              private serverValidation: ServerValidationService) { }
    salaryForm = this.fb.group({
        gross_salary: [0, [Validators.required, Validators.min(0)]],
        from_date: [null, Validators.required],
        next_inc: [null, Validators.required],
        remarks: [null],
        commit_id: [null, Validators.required],
        commit_list: [null],
        bond_period: [null],
        bond_amount: [null],
        commitment_from: [null],
        salary_detail_id: [null]
    }, {
        validator: [
            this.matchGrossSalary.bind(this),
            // this.commitListValidation.bind(this)  ,
            // this.commitBondPeriod.bind(this),
            // this.commitBondAmount.bind(this),
            // this.commitmentFromValidation.bind(this)
        ]
    });
  ngOnInit() {
    this.fetchSalaryParameters();
    this.getBondAmountListing();
    this.getCommitments();
    if (this.salaryId) {
        this.onEditRequest();
    } else {
        const dt = new Date(this.incrementFromDate);
        dt.setDate(dt.getDate() - 1);
        this.minIncrementFromDate = dt;
        this.salaryForm.get('from_date').setValue(this.incrementFromDate);
        // console.log(this.incrementFromDate);
    }
      this.salaryForm.get('commit_id').valueChanges.subscribe((commitId) => {
          this.commit_list.clearValidators();
          this.bond_period.clearValidators();
          this.bond_amount.clearValidators();
          this.commitment_from.clearValidators();
          if (commitId == 1) {
              this.commit_list.setValidators([Validators.required]);
          } else if (commitId == 2) {
              this.commit_list.setValidators([Validators.required]);
              this.bond_period.setValidators([Validators.required]);
          } else if (commitId == 3) {
              this.bond_period.setValidators([Validators.required]);
              this.bond_amount.setValidators([Validators.required]);
              this.commitment_from.setValidators([Validators.required]);
          }
      });
  }
    /**
     *  On Increment From Date change, reset min commitment from date and calculate increment to date
     *  @params value - from_date value
     * */
    onFromDateChange(value: string) {
        this.commitmentFromDate = value;
        this.salaryForm.get('commitment_from').setValue(null);
        // console.log(this.next_inc.value);
        if (this.next_inc.value) {
            this.incrementToDate = AppConstants.getNextInitialDate(value, this.next_inc.value).toDate();
            // console.log(this.incrementToDate);
        }
    }
    /**
     * Set Form Data on edit for salary parameters
     * */
    setSalaryParamData() {
        const salarySplits = this.salaryDetail['salary_splits'];
        salarySplits.forEach((splitData) => {
            if ( this.salaryForm.get(splitData['parameter_id'])) {
                this.salaryForm.get(splitData['parameter_id']).setValue(splitData['value']);
            }
        });
        this.grossSalary = this.calculateGrossSalary();
        this.salaryForm.get('gross_salary').setValue(this.grossSalary);
    }
    /**
     * On Edit set form data
     * */
    onEditRequest() {
        this.isEdit = true;
        const salaryDetailTo = new Date(this.salaryDetail['next_inc']);
        this.disableFromDate = true;
        let selectCommit = {};
         this.salaryForm.patchValue({
            'salary_detail_id': this.salaryDetail['id'],
            'gross_salary': this.salaryDetail['gross_salary'],
            'from_date': new Date(this.salaryDetail['from_date']),
            'next_inc': AppConstants.dateDiffMonths(this.salaryDetail['next_inc'], this.salaryDetail['from_date']),
            'remarks': this.salaryDetail['remarks']
        });
        this.incrementToDate = salaryDetailTo;
        this.onSalaryParamChange();
        this.commitDetails.forEach((list) => {
            if (list['id'] === this.salaryDetail['commitment_id']) {
                selectCommit = list;
            }
        });
        let commitId = null;

        if (this.salaryDetail['commitment_id']) {
            commitId = 1;
            this.getCommitList({id: commitId});
            this.salaryForm.patchValue({'commit_list': this.salaryDetail['commitment_id']});
        } else {
            commitId = 4;
            this.getCommitList({id: commitId});
        }

        this.salaryForm.patchValue({
            'commit_id': commitId
        });
        this.initalCommitType = commitId;
    }
    // returns form control
    get bond_period() {
        return this.salaryForm.get('bond_period');
    }
    get bond_amount() {
        return this.salaryForm.get('bond_amount');
    }
    get commitment_from() {
        return this.salaryForm.get('commitment_from');
    }
    get commit_list() {
        return this.salaryForm.get('commit_list');
    }
    get next_inc() {
        return this.salaryForm.get('next_inc');
    }
    /**
     * Get bond amount listing
     * */
    getBondAmountListing() {
        this.employeeService.fetchBondAmountList().subscribe((result) => {
            if (result['success']) {
                const data = result['data'];
                this.bondAmountList = data;
            }
        }, (error) => {
            this.alertService.error(error, false);
        });
    }
    /**
     * fetch list of commitments
     * */
    getCommitments() {
      this.employeeEditService.fetchCommitments(this.employeeId).subscribe((result) => {
        if (result['success']) {
            [this.commitDetails, this.commitmentList] = this.employeeEditService.modifyCommitment(result['data']);
        }
      }, (error) => {
          this.alertService.error(error, false);
      });
    }
    /**
     * On Next Increment Change
     * @params value - next inc duration value in months
     * */
    onNextIncChange(value: {id, name: string}) {
        // console.log(value);
        if ( value['id'] != '' && value['id'] != 0 && value['id'] != 'undefined') {
            this.incrementToDate = AppConstants.getNextInitialDate(this.incrementFromDate, value['id']).toDate();
        }
    }
    /**
     *  Validation function for gross salary
     * */
    matchGrossSalary(formGroup: FormGroup) {
        const  gross = this.calculateGrossSalary();
        if (+formGroup.value.gross_salary !== gross) {
            return {
                grossMismatch: true
            };
        }
        return null;
    }
    /**
     * On Salary Parameters Change recalculate gross salary
     * */
    onSalaryParamChange() {
        this.grossSalary = this.calculateGrossSalary();
        this.salaryForm.get('gross_salary').setValue(this.grossSalary);
    }
    /**
     * Compute gross salary
     * */
    calculateGrossSalary(): number {
        let gross = 0;
        const formParts = this.salaryParameters;
        formParts.map((param) => {
            gross += +this.salaryForm.value[param['id']];
        });
        return gross;
    }

    /**
     * submit handler
     * */
    submitSalary() {
        this.salarySubmitted = true;
        this.loading = true;
        let dateKeys = ['from_date', 'commitment_from'];
        let formData = {};
        formData = this.salaryForm.value;
        Object.keys(formData).forEach(key => {
            if (dateKeys.indexOf(key) !== -1 && formData[key] !== null) {
                formData [key] = AppConstants.dateFormat(formData[key]);
            }
        });
        formData['employee_id'] = this.employeeId;
        if (this.incrementDetail != undefined && Object.keys(this.incrementDetail).length > 0) {
            formData['increment_id'] = this.incrementDetail['id'];
            formData['process_remark'] = this.incrementDetail['process_remark'];
        }
        formData['to_date'] = AppConstants.dateFormat(this.incrementToDate);
        // console.log(this.isEdit, this.initalCommitType, this.finalCommitType);
        if (this.salaryForm.valid) {
            this.employeeService.saveUserSalaryDetails(formData, this.isEdit).subscribe((result) => {
                if (result['success']) {
                    this.alertService.success('Salary Details are saved successfully!', true);
                }
                // this.ngOnInit();
                this.loading = false;
                this.salaryChanged.emit(true);
            }, (error) => {
                if (error['validation']) {
                    this.server_validation = this.serverValidation.validate(JSON.parse(error['validation']));
                    this.alertService.error(JSON.stringify(this.server_validation), false) ;
                } else {
                    this.alertService.error(error, false) ;
                }
                this.loading = false;
            });

        } else {
            this.loading = false;
            console.log(this.salaryForm.controls);
            console.log(this.salaryForm.value);
            // console.log(this.salaryForm['errors']);
        }
        // this.loading = false;
    }
    prefillFormIfIncrement() {
        if (this.incrementDetail != undefined && Object.keys(this.incrementDetail).length > 0) {
            // console.log(this.incrementDetail);
            this.salaryForm.patchValue({
                'from_date': new Date(this.incrementDetail['increment_from']),
                'next_inc': +this.incrementDetail['next_increment_months'],
                'remarks': this.incrementDetail['processed_remark'],
                'gross_salary': this.incrementDetail['gross'],
            });
            this.incrementToDate = new Date(this.incrementDetail['next_increment_from']);
            this.salaryForm.get(this.appConstants['basic_id']).setValue((+this.incrementDetail['basic_amount']).toFixed());
            this.salaryForm.get(this.appConstants['pli_id']).setValue((+this.incrementDetail['pli_amount']).toFixed());
            this.grossSalary = this.calculateGrossSalary();
            let commitId = 4;
            if (this.incrementDetail['commit_type'] == 'New Commitment') {
                commitId = 3;
                // console.log(new Date(this.incrementDetail['commitment_from_date']));
                this.salaryForm.patchValue({
                    'bond_amount': this.incrementDetail['bond_id'],
                    'bond_period': this.incrementDetail['commitment_months'],
                });
            } else if (this.incrementDetail['commit_type'] == 'Under Commitment' ||
                this.incrementDetail['commit_type'] == 'Extend Commitment') {
                this.salaryForm.get('commit_list').setValue(this.incrementDetail['commitment']);
                if (this.incrementDetail['commit_type'] == 'Extend Commitment') {
                    commitId = 2;
                    this.salaryForm.get('bond_period').setValue(this.incrementDetail['extend_by_months']);
                } else {
                    commitId = 1;
                }
            }
            this.getCommitList({id: commitId});
            this.salaryForm.get('commit_id').setValue(commitId);
            if (commitId == 3) {
                // console.log(this.incrementDetail['commitment_from_date']);
                this.salaryForm.patchValue({
                    'commitment_from': (this.incrementDetail['commitment_from_date']),
                });
            }
        }
    }
    /**
     *  Return all form controls
     * */
    get salary_form_error() {
        if (!this.salaryForm.valid) {
            this.server_validation = {};
        }
        return this.salaryForm['controls'];
    }
    /**
     * Fetch all salary parameters to be used as form fields
     * */
    fetchSalaryParameters() {
        this.employeeService.getSalaryParameters().subscribe((result) => {
            if (result['success']) {
                const formParts = result['data'];
                this.salaryParameters = formParts;
                // console.log(this.getParameter('Performance'));
                 formParts.map((parameter) => {
                     this.formResetObject[parameter['id']] = 0;
                     this.salaryForm.addControl(parameter['id'], new FormControl(
                         0, [Validators.required, Validators.min(0)]
                     ));
                 });
                if (this.salaryId) {
                    this.setSalaryParamData();
                }
                if (this.incrementDetail) {
                    this.prefillFormIfIncrement();
                }
                window.scrollTo(0, 0);
            }
        }, (error) => {
            this.alertService.error(error, false);
        });

    }
    /**
     *  Hide - Show functionality for conditional fields
     * */
    getCommitList(commitId) {
        this.showCommitList = this.showBondAmount = this.showBondPeriod = false;
        this.finalCommitType = commitId['id'];
        switch (commitId['id']) {
            case 1: {
                this.showCommitList = true;
                break;
            }
            case 2: {
                this.showCommitList = true;
                this.showBondPeriod = true;
                break;
            }
            case 3: {
                this.showBondPeriod = true;
                this.showBondAmount = true;
                break;
            }
        }
        this.filterCommitList(commitId['id']);
    }
    /**
     * Re-render commit list for dropdown to enable only latest commitment
     * */
    filterCommitList(commitType) {
        let filteredList = [...this.commitmentList];
        filteredList = filteredList.map((commitment, index) => {
            if (index !== 0 && (commitType == 1 || commitType == 2)) {
                commitment.disabled = true;
            } else {
                commitment.disabled = false;
            }
            return commitment;
        });
        this.commitmentList = filteredList;
        // console.log(this.showCommitList);
        // console.log(this.commitmentList);
    }
}

