import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
// tslint:disable-next-line: max-line-length
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

const adminRoutes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'dashboard'
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'others',
      loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
    },
    {
      path: 'manage/employee',
      loadChildren: './views/manage/employee/employee.module#EmployeeModule'
    },
    {
      path: 'manage/increment-requests',
      loadChildren: () => import('./views/manage/increment/increment.module').then(m => m.IncrementModule)
    },
    {
      path: 'manage/variable-requests',
      loadChildren: () => import('./views/manage/variable/variable.module').then(m => m.VariableModule)
    },
    {
      path: 'manage/payments',
      loadChildren: () => import('./views/manage/payments/payments.module').then(m => m.PaymentsModule)
    },
    {
      path: 'manage/tickets',
      loadChildren: () => import('./views/manage/tickets/tickets.module').then(m => m.TicketsModule)
    },
    {
      path: 'manage/reports',
      loadChildren: () => import('./views/manage/reports/reports.module').then(m => m.ReportsModule)
    },
    {
      path: 'manage/documents',
      loadChildren: () => import('./views/manage/documents/documents.module').then(m => m.DocumentsModule)
    },
    {
      path: 'manage/attendance-query',
      loadChildren: () => import('./views/manage/attendance-query/attendance-query.module').then(m => m.AttendanceQueryModule)
    },
    {
      path: 'manage/left-employee',
      loadChildren: () => import('./views/manage/left-employee/left-employee.module').then(m => m.LeftEmployeeModule)
    },
    {
      path: 'manage/leave-management',
      loadChildren: () => import('./views/manage/leave-mgmt/leave-mgmt.module').then(m => m.LeaveMgmtModule)
    },
    {
      path: 'manage/acl',
      loadChildren: () => import('./views/manage/acl/acl.module').then(m => m.AclModule)
    },
    {
        path: 'manage/employee-types',
        loadChildren: () => import('./views/manage/usertype/usertype.module').then(m => m.UsertypeModule)
    }
  ];

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
      path: '',
      component: AuthLayoutComponent,
      children: [
          {
              path: 'sessions',
              loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
          }
      ]
  },
  {
    path: '**',
    redirectTo: 'dashboard' // redirect all invalid paths to dashboard
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
