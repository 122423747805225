import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {MonthsToYearMonthsPipe} from './monthsToYearMonths.pipe';
import {AcronymWordPipe} from "./acronym-word.pipe";

const pipes = [
  ExcerptPipe,
  GetValueByKeyPipe,
  RelativeTimePipe,
  DateFormatPipe,
    MonthsToYearMonthsPipe,
    AcronymWordPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule { }
