import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class ServerValidationService {
    constructor() {}
    validate(formField) {
        const server_validation = {};
        Object.keys(formField).forEach((field) => {
           server_validation[field] = formField[field][0];
        });
        return server_validation;
    }
}
