import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.scss']
})
export class ConfirmBoxComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }
  @Input() heading;
  @Input() message;
  @Input() resultValue;
  @Output() okEvent = new EventEmitter();
  submitted = false;
  ngOnInit() {
  }
  okHandler(event) {
    this.submitted = true;
    this.okEvent.emit(this.resultValue);
  }
}
