import {Directive, Input, ElementRef, OnInit, ViewContainerRef, TemplateRef} from '@angular/core';
import {AuthService} from '../services/auth.service';
import * as _ from 'lodash';

@Directive({
    selector: '[appAllowView]',
})
export class AclPermissionsDirective {
    logicalOp = 'AND';
    permissions;
    constructor(
        private templateRef: TemplateRef<any>,
        el: ElementRef, private auth: AuthService, private viewContainer: ViewContainerRef) { }

    @Input() set appAllowView(url: String|Array<any>) {
        this.permissions = url;
        this.updateView();
    }
    @Input() set appAllowViewOp(operation) {
        if (operation) {
            this.logicalOp = operation;
        }
    }
    updateView() {
        if (this.auth.checkAuthOAccess(this.permissions, this.logicalOp)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
