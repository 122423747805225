import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appCheckNull]'
})
export class CheckNullDirective {
    @Input('appCheckNull') inputValue;
    constructor(private el: ElementRef) {
        if (this.inputValue != null && this.inputValue != undefined && this.inputValue != '' && this.inputValue !=0 ){
            this.el.nativeElement.classList.remove('hidden');
        } else {
            this.el.nativeElement.classList.add('hidden');
        }
    }
}
