import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loader = new Subject();
    showLoader() {
        this.loader.next(true);
    }
    hideLoader() {
        this.loader.next(false);
    }
    getLoader() {
        return this.loader.asObservable();
    }
}