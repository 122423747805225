import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, of, Subject} from 'rxjs';
import { delay } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { AppConstants } from '../../app.constants';
import {exhaustMap, take, tap} from 'rxjs/internal/operators';
import {User} from '../../views/sessions/user.model';
import {LoaderService} from './loader.service';
import {AlertService} from './alert.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  authenticated = false;
  user = new BehaviorSubject<User>(null);
  loggedUser: User;
  expiresIn = 3600;
  token = null;
  tokenExpirationTimer;
  aclAuthOkeys = AppConstants.acl_permissions;
  constructor(private store: LocalStoreService,
              private router: Router,
              private http: HttpClient,
              private loaderService: LoaderService,
              private alertService: AlertService) {
  }
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
        const authOkeys = route.data['authOkeys'];
        const access =  this.checkAuthOAccess(authOkeys);
        console.log(access);
        if (!access) {
           this.alertService.error('Permission Denied!', false);
        }
        return access;
    }
    checkAuthOAccess(authOkeys, operation = 'AND') {
      // console.log(authOkeys);
        if (Array.isArray(authOkeys)) {
            const routePermissions = authOkeys.map((permission) => this.aclAuthOkeys[permission]);
            let result = false;
            if (operation == 'AND') {
                result = true;
            }
            routePermissions.forEach((permission) => {
                if (operation === 'OR') {
                    result = result || this.getAclUrl(permission);
                } else {
                    result = result && this.getAclUrl(permission);
                }
            });
            // console.log(result);
            return result;
        } else {
            const routePermissions = this.aclAuthOkeys[authOkeys];
            return this.getAclUrl(routePermissions);
        }
    }
    checkNavigationAccess(authOkeys, operation = 'OR') {
        if (Array.isArray(authOkeys)) {
            const routePermissions = authOkeys.map((permission) => this.aclAuthOkeys[permission]);
            let result = false;
            if (operation === 'AND') {
                result = true;
            }
            routePermissions.forEach((permission) => {
                if (operation === 'AND') {
                    result = result && this.getAclUrl(permission);
                } else {

                    result = result || this.getAclUrl(permission);
                }
                // console.log(authOkeys);
                // console.log(result);
            });
            return result;
        } else {
            const routePermissions = this.aclAuthOkeys[authOkeys];
            return this.getAclUrl(routePermissions);
        }
    }
    getAclUrl(url: string) {
        const session = this.getAuth();
        if (_.indexOf(session, url) >= 0) {
            return true;
        }
        return false;
    }
  checkAuth() {
      let tokenData = this.getClaimsFromToken();
      if (tokenData) {
          return Date.now() <= (tokenData['exp']) * 1000;
      }
      return false;
  }
  getAuth() {
      let permissions = this.store.getItem('neo_hr_details');
      if (permissions != '') {
          return JSON.parse(permissions);
      }
      return [];
  }
  getAcl() {
      //
  }
  getUser(): User {
    const user = this.getClaimsFromToken()['udetails'];
    return user;
  }
  // getLoggedUser() {
  //     this.user.subscribe((loggedUser) => {
  //         this.loggedUser = loggedUser;
  //     });
  // }
    urlBase64Decode(str) {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                console.log( 'Illegal base64url string!');
        }
        return window.atob(output);
    }

    getClaimsFromToken() {
        const token = this.store.getItem('auth_token');
        // console.log(token);
        if (token) {
            let user = {};
            if (typeof token !== 'undefined') {
                const encoded = token.split('.')[1];
                user = JSON.parse(this.urlBase64Decode(encoded));
            }
            return user;
        }
        return null;
    }
  getToken() {
      return this.store.getItem('auth_token');
  }
  setUser(userObj: User) {
      userObj.neo_login = userObj.token;
      this.store.setItem('neo_hr_user', userObj);
  }
  signin(credentials) {
    const headers = {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
    };
    this.loaderService.showLoader();
    return this.http.post(AppConstants.baseURL + '/login', credentials, {headers:  headers})
        .pipe(tap((result) => {
           // this.authenticated = true;
           //  const expirationDate = new Date(new Date().getTime() + this.expiresIn * 1000);
            // const  newUser = new User(result['user']['email'],
            //     result['user']['local_id'], result['user']['username'],
            //     result['user']['firstname'], result['user']['lastname'], result['token'], expirationDate);
            // this.user.next(newUser);
            // const expirationDuration =
            //     new Date(expirationDate).getTime() -
            //     new Date().getTime();
            // this.setUser(newUser);
            this.store.setItem('auth_token', result['token']);
            this.store.setItem('neo_hr_details', result['user']['permissions']);
            // this.store.setItem('login_status', true);
            // this.store.setItem('logged_user_id', result['user']['local_id']);
            this.loaderService.hideLoader();
        }));
  }
    /*autoLogout(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.signout();
        }, expirationDuration);
    }*/
  signout() {
    const headers = this.getHeaders();
    this.loaderService.showLoader();
    this.http.get(AppConstants.baseURL + '/logout?token=' + this.getToken(), headers).subscribe((res) => {
        this.authenticated = false;
        this.store.clear();
    }, (error) => {
        this.store.clear();
    });
      this.loaderService.hideLoader();
      this.router.navigateByUrl('/sessions/signin');
  }
  getHeaders() {
    const headers = {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
    };
    return {headers: headers};
  }
  getfileHeaders() {
    const headers = {
      'Accept' : 'application/json',
      // 'Content-Type' : 'multipart/form-data',
      'Authorization': `Bearer ${this.getToken()}`
    };
    return {headers: headers};
  }

  setHeaders(params) {
    const reqData = {
      headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      },
    };
    if (params) {
      let reqParams = {};
      Object.keys(params).map(k => {
          reqParams[k] = params[k];
      });
      reqData['params'] = reqParams;
      // console.log(reqData);
    }
    return reqData;
  }

  setFileHeaders(params) {
      const headers = {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.getToken()}`
      }
      const reqData = {headers: headers, responseType: 'blob' as 'json', params: params};
      return reqData;
  }
    visibility() {
        const id = Number(this.getUser()['role']);
        console.log(id);
        let isVisible = false;
        if (id === 1 || id === 6) {
            isVisible = true;
        }
        return isVisible;
    }
}
