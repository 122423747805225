import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-salary-view',
  templateUrl: './salary-view.component.html',
  styleUrls: ['./salary-view.component.scss']
})
export class SalaryViewComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  @Input() verticalLine = false;
  @Input() active = false;
  @Input() salaryOffered;
  @Input() index;
  salarySplits = [];
  totalSalary = 0;
  show = true;
  ngOnInit() {
    // console.log(this.salaryOffered);
  }
    open(content, splits) {
        // console.log(splits);
        this.salarySplits = splits;
        this.totalSalary = this.total_salary(splits);
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then((result) => {
        }, (reason) => {
            // console.log('Err!', reason);
        });
    }

    total_salary(salarySplits) {
      // console.log(salarySplits);
      let salary = 0;
      salarySplits.map((split) => {
          if (split['salary_parameter']['is_visible']) {
              salary += split['split_salary'];
          }
      });
      return salary;
    }
}
