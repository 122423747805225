import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, pipe, from } from 'rxjs';
import { AppConstants } from '../../app.constants';
import { AuthService } from './auth.service';
import {LoaderService} from './loader.service';
import { delay, map, tap, filter} from 'rxjs/operators';
import {stringify} from "@angular/compiler/src/util";
import {retry, retryWhen} from "rxjs/internal/operators";

export interface Departments {
    id: string;
    name: string;
    disabled?: boolean;
}

export interface Locations {
    id: string;
    name: string;
    disabled?: boolean;
}

export interface Roles {
    id: string;
    name: string;
    disabled?: boolean;
}

export interface Techs {
    id: string;
    name: string;
    disabled?: boolean;
}


@Injectable({
  providedIn: 'root'
})

export class EmployeeService {

    constructor(private http: HttpClient,
                private authService: AuthService,
                private loader: LoaderService) { }
    recruiters() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/recruiter',
            this.authService.getHeaders()).pipe((res) => {
                this.loader.hideLoader();
                return res;
            } );
        // return of({
        //     success: true,
        //     data: [
        //         {
        //           'id': 1,
        //           'name': 'Rohan'
        //         }
        //     ]
        // });
    }

    candidates(recruiter: string, search = '') {
        this.loader.showLoader();
        const postData = {
            'recruiter_id': recruiter
        };
        if (search !== '') {
            postData['search'] = search;
        }
        return this.http.post(AppConstants.baseURL + '/recruiter/candidates',
            postData,
            this.authService.getHeaders())
            .pipe(map((res) => {
                this.loader.hideLoader();
                let data = []
                if (res['data'].length > 0) {
                    data = res['data'].map((item) => {
                        if (item ['technology'] ===  null) {
                            item['technology'] = AppConstants.translations['n.a'];
                        }
                        return item;
                    });
                }
                return data;
            }));
    }

    fetchCandidate(candidateId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/candidates/' + encodeURIComponent(candidateId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                return res;
            });
    }
    checkExistEmail(email) {
        return this.http.post(AppConstants.baseURL + '/employee/emailexists', {
            email: email
            }, this.authService.getHeaders()
            ).pipe(map((res) => {
            if (res['success']) {
                if (res['data']['user_id']) {
                    return {
                        emailTaken: true
                    };
                }
            }
            return null;
        }));
    }

    fetchInternalCompanies() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/companies/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    fetchDepartments() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/departments/list', this.authService.getHeaders()).pipe((res) => {
            // this.loader.hideLoader();
            return res;
        });
    }

    fetchDepart() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/departments/list', this.authService.getHeaders()).pipe(map((res) => {
            // this.loader.hideLoader();
            return res;
        }));
    }
    fetchRoles() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/roles/list', this.authService.getHeaders()).pipe(map((res) => {
            // this.loader.hideLoader();
            return res;
        }));
    }
    fetchUserTypes() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/usertype/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    fetchOfficeLocations() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/office_location/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    fetchLocations() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/office_location/list', this.authService.getHeaders()).pipe(map((res) => {
            // this.loader.hideLoader();
            return res;
        }));
    }
    fetchTechs() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/techs/list', this.authService.getHeaders()).pipe(map((res) => {
            // this.loader.hideLoader();
            return res;
        }));
    }
    fetchServices() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/services/list', this.authService.getHeaders()).pipe(map((res) => {
            this.loader.hideLoader();
            return res;
        }));
    }
    fetchTechnologies() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/techs/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    fetchTeamLeaders(technology_id) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/team-master/team-leaders',
            {'technology_id': technology_id['id']}, this.authService.getHeaders()).pipe((res) => {
           this.loader.hideLoader();
           return res;
        });
    }
    saveUser(formData) {
      this.loader.showLoader();
      return this.http.post(AppConstants.baseURL + '/employee', formData, this.authService.getHeaders()).pipe(
          (res) => {
              this.loader.hideLoader();
              return res;
          }
      );
    }
    fetchDepartmentHeads(data) {
        return this.http.post(AppConstants.baseURL + '/department-head', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    dashboard() {
    this.loader.showLoader();
    return this.http.get(AppConstants.baseURL + '/employee/dashboard',
        this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        } );
    }
    fetchDepartmentRole(departmentid) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/department/role/' + encodeURIComponent(departmentid),
            this.authService.getHeaders()).pipe((res) => {
                this.loader.hideLoader();
                return res;
            } );
    }
    employeeslist(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/index', data,
            this.authService.getHeaders()).pipe((res) => {
                return res;
            } );
    }
    getDepartment(term: string = null): Observable<any> {
        return this.fetchDepart();
    }
    getLocation(term: string = null): Observable<any> {
        return this.fetchLocations();
    }
    getRole(term: string = null): Observable<any> {
        return this.fetchRoles();
    }
    getTech(term: string = null): Observable<any> {
        return this.fetchTechs();
    }

    filterEmployees(filterListData) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/list', filterListData, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    updaterowEmployees(updateRow) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/row', updateRow, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    updaterowRole(department) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/department/roles', department, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    fetchSalaryDetails(employeeId) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/user-salary-details',
            {'employee_id': employeeId}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    getSalaryParameters(): Observable<any> {
        return this.http.get(AppConstants.baseURL + '/salary-parameters/list',
            this.authService.getHeaders()).pipe((res) => {
            return res;
        });
    }
    updaterowTech(department) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/department/technologies', department, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    /**
     * API call to Fetch profile details of employee using employee-id
     */
    fetchEmployeeProfile(employeeId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/' + encodeURIComponent(employeeId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                return res;
            });
    }
    /**
     * API call to Update profile details of employee using employee-id
     */
    updateUserProfile(employeeId, formData) {
        this.loader.showLoader();
        console.log(formData);
        // return formData;
        return this.http.post(AppConstants.baseURL + '/employee/profile/' + encodeURIComponent(employeeId), formData, this.authService.getfileHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
      }

    /**
     * API call to Update leave status of pending request
     */
    updateLeaveStatus(leaveData) {
        this.loader.showLoader();
        console.log(leaveData);
        // return leaveData;
        return this.http.post(AppConstants.baseURL + '/leaves/statusupdate', leaveData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
      }

    /**
     * API call to Fetch skill mapping using technology-id
     */
    fetchSkillsMapping(technologyId) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/skills/' + (technologyId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }
    /**
     * API call to Fetch users skills through technoligy-id
     */
    fetchUserSkills(userId, technologyId) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/user_skills/' + encodeURIComponent(userId) + '/' + encodeURIComponent(technologyId), this.authService.getHeaders())
            .pipe((res) => {
                this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch users leaves through technoligy-id
     */
    fetchUserLeaves(userId) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/leaves/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch users leaves through technoligy-id
     */
    fetchUserLeavesLogs(userId) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/leaves/logs/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch users team leaders details through technology-id
     */
    fetchUserTeamLeaders(userId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/teams_member/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch users technology logs details
     */
    fetchTechnologyLogs(userId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/techs/log/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * API call to Fetch users designation logs details
     */
    fetchDesignationLogs(userId) {
        // this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/designation/log/' + encodeURIComponent(userId), this.authService.getHeaders())
            .pipe((res) => {
                // this.loader.hideLoader();
                // console.log(res);
                return res;
            });
    }

    /**
     * Fetch City listing
     **/
    fetchCityList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/city/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Fetch Country listing
     **/
    fetchCountryList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/country/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Fetch Bond amount listing
     **/
    fetchBondAmountList(): Observable<any> {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/bond-amount/list', this.authService.getHeaders()).pipe(
            map((res) => {
                this.loader.hideLoader();
                return res;
            })
        );
    }
    /**
     * Fetch Blood Group listing
     **/
    fetchBloodGroupList(): Observable<any> {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/blood_group/list', this.authService.getHeaders()).pipe(
            map((res) => {
                this.loader.hideLoader();
                return res;
            })
        );
    }
    /**
     *  Save user salary details
     * */
    saveUserSalaryDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/user-salary-details/update';
        } else {
             url = AppConstants.baseURL + '/user-salary-details/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }
    /**
     *  Save user team leader log details
     * */
    saveTeamLeaderLogDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/team-leader-logs/update';
        } else {
             url = AppConstants.baseURL + '/team-leader-logs/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }
    /**
     *  Save user technology log details
     * */
    saveTechnologyLogDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/techs/log/update';
        } else {
             url = AppConstants.baseURL + '/techs/log/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }
    /**
     * Delete user technology Log
     * */
    deleteUserTechnologyLog(log_id: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/techs/log/delete',
            {log_id: log_id}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     *  Save user Designation log details
     * */
    saveDesignationLogDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/designation/log/update';
        } else {
             url = AppConstants.baseURL + '/designation/log/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }
    /**
     * Delete user designation Log
     * */
    deleteUserDesignationLog(log_id: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/designation/log/delete',
            {log_id: log_id}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Delete Education Detail
     * */
    deleteUserEducationDetail(education_id: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/education/delete',
            {education_id: education_id}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Delete Work Experience Detail 
     * */
    deleteUserWorkExpDetail(experience_id: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/work/delete',
            {experience_id: experience_id}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Delete user team leader Log
     * */
    deleteUserTeamLeadLog(log_id: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/team-leader-logs/delete',
            {log_id: log_id}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     *  Save user education details
     * */
    saveUserEducationDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/employee/education/update';
        } else {
             url = AppConstants.baseURL + '/employee/education/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    /**
     *  Save user work experience details
     * */
    saveUserWorkExpDetails(formData, isEdit: boolean): Observable<any> {
        this.loader.showLoader();
        // console.log(isEdit);
        let url ='';
        if (isEdit) {
             url = AppConstants.baseURL + '/employee/work/update';
        } else {
             url = AppConstants.baseURL + '/employee/work/store';
        }
        return this.http.post(url, formData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    /**
     * Delete user salary details
     * */
    deleteUserSalaryDetails(detailId: string): Observable<any> {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/user-salary-details/delete',
            {detail_id: detailId}, this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    saveWeekend(weekendData) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/weekend/add', weekendData, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    weekendlist(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/weekend', data,
            this.authService.getHeaders()).pipe((res) => {
                this.loader.hideLoader();
                return res;
        } );
    }

    getAttendanceData(attendanceid) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance', attendanceid, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    saveOt(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/ot', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    saveOtEncash(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/otencash', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    saveStatus(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/status', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    saveRemark(data){
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/addremark', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    getRemark(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/getRemark', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    getRemarkBy(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/getRemarkBy', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    saveallStatus(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/saveallStatus', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    saveallUserStatus(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/saveallUserStatus', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    getBreakLog(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/attendance/getBreakLog', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    userInfo(id) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/info/' + encodeURIComponent(id), this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    weekendDelete(id) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/weekendDelete/' + id, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    getAllUserAttendance(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/alluserattendance/', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    getTimeZoneData(data) {
        if (data['page'] < 2) {
        this.loader.showLoader();
        }
        return this.http.post(AppConstants.baseURL + '/employee/timezonelist', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    saveTimezone(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/addtimezone', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    timezoneDelete(id) {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/employee/timezoneDelete/' + id, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    timezoneUpdate(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/updatetimezone', data, this.authService.getHeaders()).pipe(
            (res) => {
                this.loader.hideLoader();
                return res;
            }
        );
    }

    /**
     * Fetch Degree listing
     **/
    fetchDegreeList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/degrees/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Fetch Degree listing by degree type
     **/
    fetchDegreeListByType(degree_type) {
        let param = {'degree_type': degree_type};
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/degrees/list', this.authService.setHeaders(param)).pipe((res) => {
           this.loader.hideLoader();
        //    console.log(res);
           return res;
        });
    }

    /**
     * Fetch Specialization listing
     **/
    fetchSpecializationList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/specialization/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Fetch Specialization listing by degree-id
     **/
    fetchSpecializationByDegree(degree_id) {
        let param = {'degree_id': degree_id};
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/specialization/list', this.authService.setHeaders(param)).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * Fetch University listing
     **/
    fetchUniversityList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/university/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }

    /**
     * fetch departwise employee left report
     * */
    employeeLeftReport(year): Observable<any> {
        this.loader.showLoader();
        let url = AppConstants.baseURL + '/employee-report/left-report';
        if (year) {
            url += '?year=' + year;
        }
        return this.http.get(url, this.authService.getHeaders()).pipe((res) => {
           return res;
        });
    }

    getLeftEmpList(data) {
        if (data['page'] < 2) {
            this.loader.showLoader();
        }
        return this.http.post(AppConstants.baseURL + '/employee-report/leftEmployeeList', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    getAccountData(data) {
        if (data['page'] < 2) {
            this.loader.showLoader();
        }
        return this.http.post(AppConstants.baseURL + '/employee/accountData', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    updateAccount(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/updateAccount', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }
    /**
     *  Fetch all languages from master
     * */
    fetchLanguagesList() {
        this.loader.showLoader();
        return this.http.get(AppConstants.baseURL + '/language/list', this.authService.getHeaders()).pipe((res) => {
            this.loader.hideLoader();
            return res;
        });
    }
    /**
     *  save known languages
     * @param formData: Object
     * */
    saveEmployeeLanguages(formData) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/languages', formData, this.authService.getHeaders());
    }

    getBirthData(data) {
        if (data['page'] < 2) {
            this.loader.showLoader();
        }
        return this.http.post(AppConstants.baseURL + '/employee/birthData', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }

    updateBirth(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/employee/updateBirth', data, this.authService.getHeaders()).pipe(
            (res) => {
                return res;
            }
        );
    }
    /**
     *  upload docs from careers to docs server
     * */
    uploadFromCareers(documentId, data) {
        // console.log(this.enc.encrypt(data['candidateId']));
        // const formData = {
        //     documentId,
        //     employeeId: data['employeeId'],
        //     candidateId: data['candidateId'] ? (data['candidateId']) : 0
        // }
        const formData = data;
        formData.documentId = documentId;
        return this.http.post(AppConstants.baseURL + '/employee-fetch-upload', formData, this.authService.getHeaders())
            .pipe(retry(2));
    }
    /**
     *  upload profile image from careers to docs server
     * */
    uploadProfileCareers(image, data) {
        const formData = {
            image,
            employeeId: data['employeeId'],
        };
        return this.http.post(AppConstants.baseURL + '/employee-fetch-upload', formData, this.authService.getHeaders())
            .pipe(retry(2));
    }
}


