import {Injectable} from '@angular/core';
import {LoaderService} from './loader.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {LocalStoreService} from './local-store.service';
import {of} from "rxjs";
import {AppConstants} from "../../app.constants";

@Injectable({providedIn: 'root'})
export class PaymentsService {
    constructor( private loader: LoaderService,
                 private http: HttpClient,
                 private authService: AuthService,
                 private store: LocalStoreService) {
    }
    /**
     *  fetch users by technology
     * */
    getUsersByTechnology(technologyId) {
        return this.http.post(AppConstants.baseURL + '/employee/droplist', {technology_id:  technologyId},
            this.authService.getHeaders()).pipe((res) => res);
    }
    /**
     * Get payment types
     * */
    getPaymentTypes() {
        return this.http.get(AppConstants.baseURL + '/payment-types/list', this.authService.getHeaders()).pipe((res) => res);
    }
    storePayment(formData , requestId) {
        if (requestId && requestId != 0 ) {
            formData['request_id'] = requestId;
            return this.http.post(AppConstants.baseURL + '/payment-requests/update', formData,
                this.authService.getHeaders()).pipe((res) => res);
        }
        return this.http.post(AppConstants.baseURL + '/payment-requests/store', formData,
            this.authService.getHeaders()).pipe((res) => res);
    }
    getPaymentRequests(data) {
        return this.http.post(AppConstants.baseURL + '/payment-requests', data,
            this.authService.getHeaders()).pipe((res) => res);
    }
    getPaymentRequest(paymentId) {
        return this.http.post( AppConstants.baseURL + '/payment-requests/show',
            {request_id: paymentId}, this.authService.getHeaders()).pipe((res) => res);
    }
    deletePaymentRequest(requestId) {
        return this.http.post(AppConstants.baseURL + '/payment-requests/delete', {request_id: requestId},
            this.authService.getHeaders()).pipe((res) => res);
    }

    getPaymentList(data) {
        this.loader.showLoader();
        return this.http.post(AppConstants.baseURL + '/payment-requests/dashboard', data,
        this.authService.getHeaders()).pipe((res) => res);
    }
}