import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentsService} from '../../services/payments.service';
import {EmployeeService} from '../../services/employee.service';
import {LoaderService} from '../../services/loader.service';
import {AlertService} from '../../services/alert.service';
import {FormData} from '../../../form_data';
import {Subscription} from 'rxjs/index';
import {ServerValidationService} from '../../services/server-validation.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss']
})
export class PaymentsFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() paymentId = '0'; // to differentiate add and edit
    @Output() paymentSaved = new EventEmitter();
    @Input() paymentType = 0; // to differentiate from other modules
    @Input() requestData = {}; // from other module
    server_validation = {}; // server validation
    technologyList = []; // technology list
    usersList = []; // users list
    paymentTypes = []; // payment types list
    monthsList = []; // months list
    requestDetail = {}; // payment request detail in edit
    technologyId ; // set technology id
    variablePaymntTypeId = '1';
    backUrl = 'manage/payments';
    constructor(private fb: FormBuilder,
                private paymentsService: PaymentsService,
                private employeeService: EmployeeService,
                private loader: LoaderService,
                private alertService: AlertService,
                private serverValidationService: ServerValidationService,
                private router: Router) { }
    paymentForm: FormGroup = this.fb.group({
        'technology_id': [null, [Validators.required]],
        'userid': [null, [Validators.required]],
        'payment_typeid': [null, [Validators.required]],
        'payment_from': [null, [Validators.required]],
        'to_month': [null, [Validators.required]],
        'payment_to': [null, [Validators.required]],
        'amount': [0, [Validators.required]],
        'paid_by': [null, [Validators.required]],
        'remarks':  [null, [Validators.required]]
    });
    trans = FormData.getLabels(['payments']); // fetch form constants
    submitted = false; // on form submit
    loading = false; // on form submit
    otherDate = false; // show hide to date selector
    minOtherDate; // min To Date
    userLoading = false; // loader for user dropdown
    alertSubscription: Subscription;
    userId = null;
    ngOnInit() {
        // console.log(this.paymentId);
        this.subscribeAlert();
        this.fetchTechnologies();
        this.fetchPaymentTypes();
        this.monthsList = this.generateMonthsList(24);
        if (this.paymentId !== '0' && this.paymentId != null) {
            this.fetchPayment();
        }
        if (this.paymentType !== 0) {
           this.checkPaymentType();
        }
    }
    ngOnDestroy() {
        this.alertSubscription.unsubscribe();
    }
    ngAfterViewInit() {
        window.scrollTo(0, 0);
    }
    // get form controls
    get form_error() {
        if (!this.paymentForm.valid) {
            this.server_validation = {};
        }
        return this.paymentForm['controls'];
    }
    /**
     * Subscribe to subject for throwing alerts
     * */
    subscribeAlert() {
        this.alertSubscription = this.alertService.getAlert().subscribe((res) => {
            this.alertService.alertBar(res);
        });
    }
    /**
     *  fetch list of technologies
     * */
    fetchTechnologies () {
        this.employeeService.fetchTechnologies().subscribe((result) => {
            if (result['success']) {
                this.technologyList = result['data'];
            }
        }, (error) => {
            this.alertService.error(error, false);
        });
    }
    fetchUsersByTechnologyId(technology) {
        const technologyId = technology['id'];
        this.usersList = [];
        this.paymentForm.get('userid').setValue(null);
        this.userLoading = true;
        this.paymentsService.getUsersByTechnology(technologyId).subscribe((result) => {
            if (result['success']) {
                console.log(result);
                this.usersList = result['data'];
                this.userLoading = false;
                if (this.userId) {
                   this.paymentForm.get('userid').setValue(this.userId);
                }
            }
        },  (error) => {
            this.alertService.error(error, false);
        });
    }
    fetchPaymentTypes() {
        this.paymentsService.getPaymentTypes().subscribe((result) => {
            if (result['success']) {
                this.paymentTypes = result['data'];
            }
        }, (error) => {
            this.alertService.error(error, false);
        });
    }
    fetchPayment() {
        this.loader.showLoader();
        this.paymentsService.getPaymentRequest(this.paymentId).subscribe((result) => {
            if (result['success']) {
                this.requestDetail = result['data'];
                console.log(this.requestDetail);
                this.paymentForm.patchValue({
                    // 'technology_id': [null, [Validators.required]],
                    // 'userid': this.requestDetail['employee_id'],
                    'payment_typeid': this.requestDetail['payment_typeid'],
                    'payment_from': new Date(this.requestDetail['payment_from']),
                    'to_month': this.requestDetail['to_month'],
                    'payment_to': new Date(this.requestDetail['payment_to']),
                    'amount': this.requestDetail['request_amount'],
                    'paid_by': new Date(this.requestDetail['paid_by']),
                    'remarks': this.requestDetail['remarks'],
                });
                this.userId = this.requestDetail['employee_id'];
                this.onToMonthsChange({id: this.requestDetail['to_month']});
                this.patchTechnology(this.requestDetail['employee_details']['typeid']);
                this.loader.hideLoader();
            }
        }, (error) => {
            this.alertService.error(error, false);
        });
    }
    generateMonthsList(count) {
        const months = [];
        for (let i = 1; i <= count; i++) {
            if (i === 1) {
                months.push({id: `${i}`, name: i + ' month'});
            } else {
                months.push({id: `${i}`, name: i + ' months'});
            }
        }
        months.push({id: '0', name: 'Other'});
        return months;
    }
    onToMonthsChange(month) {
        const fromDate = this.paymentForm.get('payment_from').value;
        if (month['id'] === '0') {
            this.otherDate = true;
            this.minOtherDate = fromDate;
            // this.paymentForm.get('payment_to').setValue(null);
        } else {
            this.otherDate = false;
            if (!isNaN(fromDate.getTime())) {
                const toDate = AppConstants.getCommitmentToDate(fromDate, month['id']).format('YYYY-MM-DD');
                this.paymentForm.get('payment_to').setValue(new Date(toDate));
                console.log(toDate);
            }
        }
    }
    onFromDateChange(value) {
        const monthValue = this.paymentForm.get('to_month').value;
        if (monthValue !== 'others' && monthValue != null)  {
            const toDate = AppConstants.getCommitmentToDate(value, monthValue).format('YYYY-MM-DD');
            this.paymentForm.get('payment_to').setValue(new Date(toDate));
            console.log(toDate);
        }
    }
    submit() {
        this.submitted = true;
        this.loading = true;
        if (this.paymentForm.valid) {
            this.loader.showLoader();
            const formData = this.paymentForm.value;
            const technology = _.find(this.technologyList, ['id', formData.technology_id]);
            formData['department_id'] = (technology) ? technology.department : 0;
            formData['payment_from'] = AppConstants.dateFormat(formData['payment_from']);
            formData['payment_to'] = AppConstants.dateFormat(formData['payment_to']);
            formData['paid_by'] = AppConstants.dateFormat(formData['paid_by']);
            if (this.paymentType) {
               formData['variable_request'] = this.requestData['request_data'].id;
               formData['variable_remark'] = this.requestData['remark'];
            }
            console.log(formData);
            this.paymentsService.storePayment(formData, this.paymentId).subscribe((result) => {
               if (result['success']) {
                   if (this.paymentId !== '0') {
                       this.alertService.success('Payment Request edited sucessfully', false);
                   } else {
                       if (this.paymentType === 1) {
                           this.alertService.success('Variable request was processed', false);
                           this.router.navigate(['/manage/variable-requests']);
                       }
                       this.alertService.success('Payment Request Added sucessfully', false);
                   }
                   this.loader.hideLoader();
                   this.loading = false;
                   this.paymentSaved.emit(true);
               }
            }, (error) => {
                this.loader.hideLoader();
                this.loading = false;
                if (error['validation']) {
                    this.server_validation = this.serverValidationService.validate(JSON.parse(error['validation']));
                    console.log(this.server_validation);
                } else {
                    this.alertService.error(error, false);
                }
            });
        }
    }
    checkPaymentType() {
        switch (this.paymentType) {
            case 1: {this.fillVariablePayment();
            this.backUrl = 'manage/variable-requests';
            break; }
        }
    }
    checkMonthExist(month): boolean {
        const findMonth = _.find(this.monthsList, ['id', month]);
        return (findMonth) ? true : false;
    }
    fillVariablePayment() {
        const reqData = this.requestData['request_data'];
        this.fetchUsersByTechnologyId({id: reqData['employee_details']['techid']});
        console.log(this.requestData);
        this.paymentForm.patchValue({
            'technology_id': reqData['employee_details']['techid'],
            'userid': reqData['employee_id'],
            'payment_typeid': this.variablePaymntTypeId,
            'payment_from': new Date(reqData['variable_from_date']),
            'to_month': this.checkMonthExist(reqData['duration']) ? reqData['duration'] : 0,
            'payment_to': new Date(reqData['variable_to_date']),
            'amount': this.requestData['processed_amount'],
            'remarks': this.requestData['remark'],
        });
        this.userId = reqData['employee_id'];
        this.onToMonthsChange({id: this.checkMonthExist(reqData['duration']) ? reqData['duration'] : 0});
    }
    patchTechnology(typeid) {
        if (this.technologyList.length > 0) {
            const techId = _.find(this.technologyList, ['name', typeid ]);
            if (techId) {
                this.paymentForm.get('technology_id').setValue(techId['id']);
                this.fetchUsersByTechnologyId(techId);
            }
        }
    }
    backward() {
        if (this.paymentType === 1) {
            this.paymentSaved.emit(false);
        } else {
            this.router.navigateByUrl('manage/payments/list');
        }
    }
}
