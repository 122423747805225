import {FormGroup, Validators} from '@angular/forms';

export function RequiredIf(predicate) {
    return (formControl => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    });
}
