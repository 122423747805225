import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject, of, concat, } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LeaveMgmtService } from 'src/app/shared/services/leave-mgmt.service';

@Component({
  selector: 'app-auto-search',
  templateUrl: './auto-search.component.html',
  styleUrls: ['./auto-search.component.scss']
})
export class AutoSearchComponent implements OnInit {

  autocomplete$: Observable<any>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  @Output() selectedEmail = new EventEmitter<any>();

  constructor(
    private leaveService: LeaveMgmtService,
  ) { }

  ngOnInit() {
    this.getEmails();
  }

  /**
   *  To receive list of user emails
   * */
  getEmails() {
    this.autocomplete$ = concat(of([]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.peopleLoading = true),
        switchMap(term => this.leaveService.getUsersList(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.peopleLoading = false)
        ))
      )
    );
  }

  trackByFn(item) {
    return item.email;
  }

  // filteration
  changeEmp(event) {
      this.selectedEmail.emit(event.email);
  }

}
