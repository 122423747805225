import {environment} from '../environments/environment';
import * as _ from 'lodash';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AuthService } from '../app/shared/services/auth.service';

export class AppConstants {
    /**
     *  Get BaseUrl for api requests
     * */
    public static get baseURL(): string {
        return environment.baseUrl;
    }
    /**
     *  Get enc keys
     * */
    public static get encKey(): string {
        return 'HRMSCandidatePortal';
        // return 'neosofthrportals';
    }
    public static get translations() {
        return {
          'n.a': 'N.A',
            'serial_no': 'Sr.No',
            'pf': 'PF',
            'pli': 'P.L.I',
            'remarks': 'Remarks',
            'no_records': 'No Records Found',
            'no_record': 'No Record Found',
            'pli_id': '2kaQ-G2qpH7PDSQ0oVeDLg==', //16
            'basic_id': '3VxG524XLbX4QIYlAIdhDw==', //12
            'pf_id': 'E-r23B2RbeTVd2Zlyl3oSg==', //23
            'pagination': '30',
            'months_list': [
                {id: 1, name: 'Jan'}, {id: 2, name: 'Feb'}, {id: 3, name: 'Mar'},
                {id: 4, name: 'Apr'}, {id: 5, name: 'May'}, {id: 6, name: 'Jun'},
                {id: 7, name: 'July'}, {id: 8, name: 'Aug'}, {id: 9, name: 'Sept'},
                {id: 10, name: 'Oct'}, {id: 11, name: 'Nov'}, {id: 12, name: 'Dec'}
            ]
        };
    }
    /**
     * Convert date object to format 'YYYY-MM-DD'
     * @params date - date object or date as string
     * */
    public static dateFormat(date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    /**
     * Convert date object to format 'YYYY'
     * @params date - date object or date as string
     * */
    public static yearFormat(date): string {
        return moment(date).format('YYYY');
    }
    /**
     *  Get date diff in days
     *  @params date1 - from date, date2 - to date
     * */
    public static getNoOfDays(date1: Date, date2: Date) {
        const date = Math.floor((Date.UTC(date2.getFullYear(),
            date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(),
            date1.getMonth(), date1.getDate()) ) / (1000 * 60 * 60 * 24));
        return date;
    }
    /**
     *  to modify date as 1st Apr , 2020
     *  @params date - date as Date or string
     * */
    public static modifyDate(date) {
        return moment(date).format('Do MMM, YYYY');
    }

    public static roundNumbers(number, precision = 0) {
        return _.round(number, precision);
    }
    /**
     *  Difference between dates in months
     *  @params toDate - to date, fromDate - from date
     * */
    public static dateDiffMonths(toDate, fromDate) {
        return _.round(moment(toDate).diff(moment(fromDate), 'days') / 30);
    }

    public static makeNgbStruct(date: string) {
        const newDate = moment(date);
        // console.log({year: newDate.year(), month: newDate.month()+1, day: newDate.date()});
        return {year: newDate.year(), month: newDate.month() + 1, day: newDate.date()};
    }
    public static ngbDateFormat(date: NgbDateStruct): string {
        return date ? `${date.year}-${_.isNumber(date.month) ? _.padStart(`${date.month}`, 2, '0') : ''}-${_.isNumber(date.day) ? _.padStart(`${date.day}`, 2, '0') : ''}` : '';
    }

    public static fulltime() {
        const full = 570;
        return full;
    }

    public static midtime() {
        const mid = 450;
        return mid;
    }

    public static halftime() {
        const half = 270;
        return half;
    }

    public static breaktime() {
        return 45;
    }
    /**
     *  To get first date of the month (15 days logic)
     *  @params initialDate - date object
     * */
    public static getInitialDate(initialDate) {
        const day = initialDate.getDate();
        if ( day <= 15 ) {
            return new Date(initialDate.getFullYear(), initialDate.getMonth()  , 1);
        } else {
            if (initialDate.getMonth() === 11) {
                return new Date(initialDate.getFullYear() + 1, 0, 1);
            } else {
                return new Date(initialDate.getFullYear() , initialDate.getMonth() + 1, 1);
            }
        }
    }
    /**
     *  Get Next Initial Date after a duration of months (15 days logic)
     *  @params initialDate - start date, months  - months to add
     * */
    public static getNextInitialDate(initialDate, months: string | number) {
        const day = initialDate.getDate();
        let newDate = initialDate;
        if ( day <= 15 ) {
            newDate =  new Date(initialDate.getFullYear(), initialDate.getMonth()  , 1);
        } else {
            if (initialDate.getMonth() == 11) {
                 newDate = new Date(initialDate.getFullYear() + 1, 0, 1);
            } else {
                 newDate = new Date(initialDate.getFullYear() , initialDate.getMonth() + 1, 1);
            }
        }
        return moment(newDate).add(months, 'months');
    }
    /**
     * Get to date / last date / end date
     *  @params fromDate - from date, months - months to add
     * */
    public static getCommitmentToDate(fromDate: Date, months: string | number) {
        return moment(fromDate).add(months, 'months').subtract(1, 'day');
    }
    /**
     *  Generate a list of last countY years
     *  @params  countY - no of years in list
     * */
    public static yearsList(countY) {
        const years = [];
        let currentYear = new Date().getFullYear();
        for (let i = 1; i <= countY; i++) {
            years.push({id: currentYear, name: currentYear});
            currentYear = currentYear - 1;
        }
        return years;
    }
    /**
     * Get duration in year and months between dates
     * @params fromDate - from date/ start date , toDate - end date
     * */
    public static getDurationInYearMonth(fromDate, toDate) {
        let duration = '0 months';
        const to_month = moment(toDate);
        const from_month = moment(fromDate);
        const years = to_month.diff(fromDate, 'year');
        from_month.add(years, 'years');
        const months = to_month.diff(from_month, 'months');
        if (months === 0) {
            duration = `${years} years`;
        } else if (years === 0) {
            duration = `${months} months`;
        } else if (months > 0 && years > 0) {
            duration = `${years} years and ${months} months`;
        }
        return duration;
    }
    /**
     *  Convert months to year months
     *  @params months - months as input
     * */
    public static convertMonthsToYearMonths(months) {
        const years = Math.floor(months / 12);
        const month = Math.floor(months % 12);
        const monthT = month > 1 ? 'months' : ( month === 1 ? 'month' : 'months');
        const yearT = years > 1 ? 'years' : ( years === 1 ? 'year' : 'years');
        let duration = '';
        if (month === 0) {
            duration = `${years} ${yearT}`;
        } else if (years === 0) {
            duration = `${month} ${monthT}`;
        } else if (month > 0 && years > 0) {
            duration = `${years} ${yearT} and ${month} ${monthT}`;
        }
        return duration;
    }

    public static monthList() {
        const monthList = [
            {'id': '0', 'name': 'January', 'value': '1', 'nm': 'Jan'},
            {'id': '1', 'name': 'February', 'value': '2', 'nm': 'Feb'},
            {'id': '2', 'name': 'March', 'value': '3', 'nm': 'Mar'},
            {'id': '3', 'name': 'April', 'value': '4', 'nm': 'Apr'},
            {'id': '4', 'name': 'May', 'value': '5', 'nm': 'May'},
            {'id': '5', 'name': 'June', 'value': '6', 'nm': 'Jun'},
            {'id': '6', 'name': 'July', 'value': '7', 'nm': 'Jul'},
            {'id': '7', 'name': 'August', 'value': '8', 'nm': 'Aug'},
            {'id': '8', 'name': 'September', 'value': '9', 'nm': 'Sep'},
            {'id': '9', 'name': 'October', 'value': '10', 'nm': 'Oct'},
            {'id': '10', 'name': 'November', 'value': '11', 'nm': 'Nov'},
            {'id': '11', 'name': 'December', 'value': '12', 'nm': 'Dec'}
        ];
        return monthList;
    }

    public static yearList(countY) {
        const years = [];
        let currentYear = new Date().getFullYear();
        for (let i = 1; i <= countY; i++) {
            years.push({id: currentYear, value: currentYear});
            currentYear = currentYear + 1;
        }
        return years;
    }

    public static getSalaryParameter(param) {
        const paramValues = {'PF' : this.translations['pf_id'], 'PLI' : this.translations['pli_id']};
        return paramValues[param];
    }
    public static validNotNull(value) {
        if (value != null && value != undefined && value != 0 && value != '') {
            return true;
        }
        return false;
    }

    public static monthremainder(value) {
        let months = 0;
        if (value > 11) {
            months = Math.floor(value % 12);
        } else {
            months = value;
        }
        let val = '';
        val = months > 0 ? months > 1 ? `${months} Months` : `${months} Month` : '';
        return val;
    }

    public static monthtoyear(value) {
        let years = 0;
        if (value > 11) {
            years = Math.floor(value / 12);
        } else {
            years = value['experience'] * 1;
        }
        let val = '';
        val = years > 0 ? years > 1 ? `${years} Years` : `${years} Year` : '';
        return val;
    }

    public static paginationList() {
        const list = [50, 100, 200];
        return list;
    }
    public static infinitescroll() {
        const list = {'scrollDistance': '2', 'scrollUpDistance': '1.5', 'scrollThrottle': '200', 'immediateCheck': 'true'};
        return list;
    }

    public static AtoZList() {
        const list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        return list;
    }
    public static get acl_permissions() {
        // frontend : backend
        const permissions = {
            AclControl_destroy: 'AclControl_destroy',
            AclControl_fetchWithPermissions: 'AclControl_fetchWithPermissions',
            AclControl_index: 'AclControl_index',
            AclControl_store: 'AclControl_store',
            AclControl_update: 'AclControl_update',
            AclPermiss_destroy: 'AclPermiss_destroy',
            AclPermiss_index: 'AclPermiss_index',
            AclPermiss_store: 'AclPermiss_store',
            AclPermiss_update: 'AclPermiss_update',
            AclRoleCon_assignPermissions: 'AclRoleCon_assignPermissions',
            AclRoleCon_destroy: 'AclRoleCon_destroy',
            AclRoleCon_index: 'AclRoleCon_index',
            AclRoleCon_permissions: 'AclRoleCon_permissions',
            AclRoleCon_store: 'AclRoleCon_store',
            AclRoleCon_update: 'AclRoleCon_update',
            AclUserRol_destroy: 'AclUserRol_destroy',
            AclUserRol_getEmployeeList: 'AclUserRol_getEmployeeList',
            AclUserRol_index: 'AclUserRol_index',
            AclUserRol_permissions: 'AclUserRol_permissions',
            AclUserRol_store: 'AclUserRol_store',
            ApiControl_getAuthUser: 'ApiControl_getAuthUser',
            ApiControl_logout: 'ApiControl_logout',
            BloodGroup_bloodGroupListing: 'BloodGroup_bloodGroupListing',
            BondAmount_bondAmountListing: 'BondAmount_bondAmountListing',
            CandidateC_addCandidate: 'CandidateC_addCandidate',
            CandidateC_fetchAllCandidates: 'CandidateC_fetchAllCandidates',
            CandidateC_fetchCandidateById: 'CandidateC_fetchCandidateById',
            CandidateC_getCandidatesOfRecruiter: 'CandidateC_getCandidatesOfRecruiter',
            CandidateE_addEducationDetails: 'CandidateE_addEducationDetails',
            CandidateE_fetchEducation: 'CandidateE_fetchEducation',
            CandidateS_addSalaryOffered: 'CandidateS_addSalaryOffered',
            CandidateS_addSalaryOfferedSplits: 'CandidateS_addSalaryOfferedSplits',
            CandidateS_addSelectionOffer: 'CandidateS_addSelectionOffer',
            CandidateS_fetchCandidateselectionOffer: 'CandidateS_fetchCandidateselectionOffer',
            CandidateS_fetchSalaryOfferedSplits: 'CandidateS_fetchSalaryOfferedSplits',
            CandidateW_addWorkExperience: 'CandidateW_addWorkExperience',
            CandidateW_fetchWorkExperience: 'CandidateW_fetchWorkExperience',
            CityContro_cityList: 'CityContro_cityList',
            Commitment_delete: 'Commitment_delete',
            Commitment_index: 'Commitment_index',
            Commitment_store: 'Commitment_store',
            Commitment_update: 'Commitment_update',
            CommonSkil_fetchSkills: 'CommonSkil_fetchSkills',
            CommonSkil_fetchSkillsByTechnology: 'CommonSkil_fetchSkillsByTechnology',
            CommonSkil_fetchUserSkills: 'CommonSkil_fetchUserSkills',
            CompanyCon_companyList: 'CompanyCon_companyList',
            CompanyCon_destroy: 'CompanyCon_destroy',
            CompanyCon_index: 'CompanyCon_index',
            CompanyCon_show: 'CompanyCon_show',
            CompanyCon_store: 'CompanyCon_store',
            CompanyCon_update: 'CompanyCon_update',
            CountryCon_countryList: 'CountryCon_countryList',
            DashboardC_attritionRate: 'DashboardC_attritionRate',
            DashboardC_changeEmployeeCount: 'DashboardC_changeEmployeeCount',
            DashboardC_fetchBirthAnniversaries: 'DashboardC_fetchBirthAnniversaries',
            DashboardC_fetchHeadCount: 'DashboardC_fetchHeadCount',
            DashboardC_fetchRequestsDashboard: 'DashboardC_fetchRequestsDashboard',
            DashboardC_fetchWorkAnniversaries: 'DashboardC_fetchWorkAnniversaries',
            DegreeCont_degreeList: 'DegreeCont_degreeList',
            DegreeCont_destroy: 'DegreeCont_destroy',
            DegreeCont_index: 'DegreeCont_index',
            DegreeCont_show: 'DegreeCont_show',
            DegreeCont_store: 'DegreeCont_store',
            DegreeCont_update: 'DegreeCont_update',
            Department_departmentList: 'Department_departmentList',
            Department_destroy: 'Department_destroy',
            Department_index: 'Department_index',
            Department_show: 'Department_show',
            Department_store: 'Department_store',
            Department_update: 'Department_update',
            Designatio_deleteDesignationLog: 'Designatio_deleteDesignationLog',
            Designatio_fetchEmployeeDesignationLogs: 'Designatio_fetchEmployeeDesignationLogs',
            Designatio_storeDesignationLog: 'Designatio_storeDesignationLog',
            Designatio_updateDesignationLog: 'Designatio_updateDesignationLog',
            DocumentTy_documentTypeListing: 'DocumentTy_documentTypeListing',
            EducationC_changeHighestQualification: 'EducationC_changeHighestQualification',
            EducationC_deleteEducationDetails: 'EducationC_deleteEducationDetails',
            EducationC_storeEducationDetails: 'EducationC_storeEducationDetails',
            EducationC_updateEducationDetails: 'EducationC_updateEducationDetails',
            EmployeeIn_index: 'EmployeeIn_index',
            EmployeeIn_show: 'EmployeeIn_show',
            EmployeeRe_joinedReport: 'EmployeeRe_joinedReport',
            EmployeeRe_joiningReportList: 'EmployeeRe_joiningReportList',
            EmployeeRe_leftEmployeeList: 'EmployeeRe_leftEmployeeList',
            EmployeeRe_leftReport: 'EmployeeRe_leftReport',
            GradeContr_gradeList: 'GradeContr_gradeList',
            LanguageCo_languageList: 'LanguageCo_languageList',
            LanguageCo_saveLanguages: 'LanguageCo_saveLanguages',
            LeaveAppli_addComments: 'LeaveAppli_addComments',
            LeaveAppli_delete: 'LeaveAppli_delete',
            LeaveAppli_fetchById: 'LeaveAppli_fetchById',
            LeaveAppli_fetchLeavesLogs: 'LeaveAppli_fetchLeavesLogs',
            LeaveAppli_filter: 'LeaveAppli_filter',
            LeaveAppli_forwardLeaveApplication: 'LeaveAppli_forwardLeaveApplication',
            LeaveAppli_index: 'LeaveAppli_index',
            LeaveAppli_planned: 'LeaveAppli_planned',
            LeaveAppli_show: 'LeaveAppli_show',
            LeaveAppli_updateStatus: 'LeaveAppli_updateStatus',
            LeftEmploy_add: 'LeftEmploy_add',
            LeftEmploy_dashboard: 'LeftEmploy_dashboard',
            LeftEmploy_leftEmpList: 'LeftEmploy_leftEmpList',
            LeftEmploy_leftemailexist: 'LeftEmploy_leftemailexist',
            LeftEmploy_reasonMasterData: 'LeftEmploy_reasonMasterData',
            LeftEmploy_update: 'LeftEmploy_update',
            OfficeLoca_officeLocationList: 'OfficeLoca_officeLocationList',
            PaymentReq_dashboard: 'PaymentReq_dashboard',
            PaymentReq_delete: 'PaymentReq_delete',
            PaymentReq_index: 'PaymentReq_index',
            PaymentReq_paymentTypeList: 'PaymentReq_paymentTypeList',
            PaymentReq_show: 'PaymentReq_show',
            PaymentReq_store: 'PaymentReq_store',
            PaymentReq_update: 'PaymentReq_update',
            ReportCont_WorkExperienceList: 'ReportCont_WorkExperienceList',
            ReportCont_attendanceQueryDashboard: 'ReportCont_attendanceQueryDashboard',
            ReportCont_attendanceQueryEmp: 'ReportCont_attendanceQueryEmp',
            ReportCont_attendanceQueryList: 'ReportCont_attendanceQueryList',
            ReportCont_attendanceQueryYear: 'ReportCont_attendanceQueryYear',
            ReportCont_biometricData: 'ReportCont_biometricData',
            ReportCont_codeExist: 'ReportCont_codeExist',
            ReportCont_companyEmpList: 'ReportCont_companyEmpList',
            ReportCont_companyList: 'ReportCont_companyList',
            ReportCont_contactList: 'ReportCont_contactList',
            ReportCont_educationList: 'ReportCont_educationList',
            ReportCont_emailExist: 'ReportCont_emailExist',
            ReportCont_fetchEmployeeExperience: 'ReportCont_fetchEmployeeExperience',
            ReportCont_getEducationDetail: 'ReportCont_getEducationDetail',
            ReportCont_getQueryDetail: 'ReportCont_getQueryDetail',
            ReportCont_incrementDueList: 'ReportCont_incrementDueList',
            ReportCont_joineesList: 'ReportCont_joineesList',
            ReportCont_locationEmpList: 'ReportCont_locationEmpList',
            ReportCont_officeLocationList: 'ReportCont_officeLocationList',
            ReportCont_personalData: 'ReportCont_personalData',
            ReportCont_probationList: 'ReportCont_probationList',
            ReportCont_removeTeam: 'ReportCont_removeTeam',
            ReportCont_salaryOfferedList: 'ReportCont_salaryOfferedList',
            ReportCont_serviceList: 'ReportCont_serviceList',
            ReportCont_teamEmployee: 'ReportCont_teamEmployee',
            ReportCont_teamleadList: 'ReportCont_teamleadList',
            ReportCont_technologyEmp: 'ReportCont_technologyEmp',
            ReportCont_technologyList: 'ReportCont_technologyList',
            ReportCont_toAndCcData: 'ReportCont_toAndCcData',
            ReportCont_updateBiometricDetails: 'ReportCont_updateBiometricDetails',
            ReportCont_updateCompanyDetails: 'ReportCont_updateCompanyDetails',
            ReportCont_updateContactDetails: 'ReportCont_updateContactDetails',
            ReportCont_updateOfficeLocationDetails: 'ReportCont_updateOfficeLocationDetails',
            ReportCont_updatePersonalDetails: 'ReportCont_updatePersonalDetails',
            ReportCont_updateSalaryDetails: 'ReportCont_updateSalaryDetails',
            ReportCont_updateService: 'ReportCont_updateService',
            ReportCont_updateTeam: 'ReportCont_updateTeam',
            ReportCont_updateTechnology: 'ReportCont_updateTechnology',
            ReportCont_updateToAndCcDetails: 'ReportCont_updateToAndCcDetails',
            ReportCont_addViewLog: 'ReportCont_addViewLog',
            ResourceCa_categoryList: 'ResourceCa_categoryList',
            ResourceCa_destroy: 'ResourceCa_destroy',
            ResourceCa_index: 'ResourceCa_index',
            ResourceCa_show: 'ResourceCa_show',
            ResourceCa_store: 'ResourceCa_store',
            ResourceCa_update: 'ResourceCa_update',
            RoleContro_destroy: 'RoleContro_destroy',
            RoleContro_index: 'RoleContro_index',
            RoleContro_roleList: 'RoleContro_roleList',
            RoleContro_show: 'RoleContro_show',
            RoleContro_store: 'RoleContro_store',
            RoleContro_update: 'RoleContro_update',
            SalaryPara_addSalaryparameter: 'SalaryPara_addSalaryparameter',
            SalaryPara_salaryParameters: 'SalaryPara_salaryParameters',
            ServicesCo_destroy: 'ServicesCo_destroy',
            ServicesCo_index: 'ServicesCo_index',
            ServicesCo_serviceList: 'ServicesCo_serviceList',
            ServicesCo_show: 'ServicesCo_show',
            ServicesCo_store: 'ServicesCo_store',
            ServicesCo_update: 'ServicesCo_update',
            Specializa_specializationList: 'Specializa_specializationList',
            TeamContro_deleteTeamLeaderLog: 'TeamContro_deleteTeamLeaderLog',
            TeamContro_fetchEmployeeteamLeaders: 'TeamContro_fetchEmployeeteamLeaders',
            TeamContro_fetchTeamLeaders: 'TeamContro_fetchTeamLeaders',
            TeamContro_storeTeamLeaderLog: 'TeamContro_storeTeamLeaderLog',
            TeamContro_updateTeamLeaderLog: 'TeamContro_updateTeamLeaderLog',
            Technology_deleteTechnologyLog: 'Technology_deleteTechnologyLog',
            Technology_fetchEmployeeTechnologyLogs: 'Technology_fetchEmployeeTechnologyLogs',
            Technology_storeTechnologyLog: 'Technology_storeTechnologyLog',
            Technology_updateTechnologyLog: 'Technology_updateTechnologyLog',
            TemplateCo_create: 'TemplateCo_create',
            TemplateCo_destroy: 'TemplateCo_destroy',
            TemplateCo_edit: 'TemplateCo_edit',
            TemplateCo_index: 'TemplateCo_index',
            TemplateCo_show: 'TemplateCo_show',
            TemplateCo_store: 'TemplateCo_store',
            TemplateCo_update: 'TemplateCo_update',
            TicketCont_downloadAttachment: 'TicketCont_downloadAttachment',
            TicketCont_index: 'TicketCont_index',
            TicketCont_saveResponce: 'TicketCont_saveResponce',
            TicketCont_ticketDashboard: 'TicketCont_ticketDashboard',
            TicketCont_ticketDashboardYear: 'TicketCont_ticketDashboardYear',
            TicketCont_ticketView: 'TicketCont_ticketView',
            University_universityList: 'University_universityList',
            UserContro_accountData: 'UserContro_accountData',
            UserContro_addRemark: 'UserContro_addRemark',
            UserContro_addTimezone: 'UserContro_addTimezone',
            UserContro_allUserAttendance: 'UserContro_allUserAttendance',
            UserContro_create: 'UserContro_create',
            UserContro_departmentWiseRole: 'UserContro_departmentWiseRole',
            UserContro_departmentWiseRoles: 'UserContro_departmentWiseRoles',
            UserContro_departmentWiseTech: 'UserContro_departmentWiseTech',
            UserContro_destroy: 'UserContro_destroy',
            UserContro_edit: 'UserContro_edit',
            UserContro_emailExist: 'UserContro_emailExist',
            UserContro_employeeRow: 'UserContro_employeeRow',
            UserContro_employeesList: 'UserContro_employeesList',
            UserContro_getAttendance: 'UserContro_getAttendance',
            UserContro_getBreakLog: 'UserContro_getBreakLog',
            UserContro_getEmployeeInfo: 'UserContro_getEmployeeInfo',
            UserContro_getEmployeeList: 'UserContro_getEmployeeList',
            UserContro_getRecruiter: 'UserContro_getRecruiter',
            UserContro_getRemark: 'UserContro_getRemark',
            UserContro_getRemarkBy: 'UserContro_getRemarkBy',
            UserContro_getWeekend: 'UserContro_getWeekend',
            UserContro_index: 'UserContro_index',
            UserContro_saveallStatus: 'UserContro_saveallStatus',
            UserContro_saveallUserStatus: 'UserContro_saveallUserStatus',
            UserContro_show: 'UserContro_show',
            UserContro_store: 'UserContro_store',
            UserContro_timezoneDelete: 'UserContro_timezoneDelete',
            UserContro_timezoneList: 'UserContro_timezoneList',
            UserContro_update: 'UserContro_update',
            UserContro_updateAccount: 'UserContro_updateAccount',
            UserContro_updateOt: 'UserContro_updateOt',
            UserContro_updateOtEncash: 'UserContro_updateOtEncash',
            UserContro_updateProfile: 'UserContro_updateProfile',
            UserContro_updateStatus: 'UserContro_updateStatus',
            UserContro_updateTimezone: 'UserContro_updateTimezone',
            UserContro_userDashboard: 'UserContro_userDashboard',
            UserContro_weekendAdd: 'UserContro_weekendAdd',
            UserContro_weekendDelete: 'UserContro_weekendDelete',
            UserContro_uploadFromCareers: 'UserContro_uploadFromCareers',
            UserDocume_deleteDocument: 'UserDocume_deleteDocument',
            UserDocume_downloadFile: 'UserDocume_downloadFile',
            UserDocume_fetchAllDocuments: 'UserDocume_fetchAllDocuments',
            UserDocume_getDocuments: 'UserDocume_getDocuments',
            UserDocume_show: 'UserDocume_show',
            UserDocume_storeDocument: 'UserDocume_storeDocument',
            UserDocume_updateDocument: 'UserDocume_updateDocument',
            UserSalary_deleteSalary: 'UserSalary_deleteSalary',
            UserSalary_index: 'UserSalary_index',
            UserSalary_storeSalary: 'UserSalary_storeSalary',
            UserSalary_updateSalary: 'UserSalary_updateSalary',
            UsertypeCo_usertypeList: 'UsertypeCo_usertypeList',
            UsertypeCo_index: 'UsertypeCo_index',
            UsertypeCo_store: 'UsertypeCo_store',
            UsertypeCo_update: 'UsertypeCo_update',
            UsertypeCo_destroy: 'UsertypeCo_destroy',
            VariableRe_index: 'VariableRe_index',
            VariableRe_show: 'VariableRe_show',
            VariableRe_update: 'VariableRe_update',
            WorkExperi_deleteWorkDetails: 'WorkExperi_deleteWorkDetails',
            WorkExperi_storeWorkDetails: 'WorkExperi_storeWorkDetails',
            WorkExperi_updateWorkDetails: 'WorkExperi_updateWorkDetails',
            MisDepartm_index: 'MisDepartm_index',
            CandidateC_fetchCandidateDocuments: 'CandidateC_fetchCandidateDocuments',
            CandidateC_fetchDocument: 'CandidateC_fetchDocument'
        };
        return permissions;
    }

    public static role(roleId) {
        const id = Number(roleId);
        switch (id) {
            case 1:
                return 'Admin';
            case 2:
                return 'HR Generalist';
            case 3:
                return 'HR Onboarding';
            case 6:
                return 'Super Admin';
            default:
                return 'Invalid User';
        }
    }

    public static visibility(roleId) {
        const id = Number(roleId);
        let isVisible = false;
        if (id === 1 || id === 6) {
            isVisible = true;
        }
        return isVisible;
    }
    /**
     *  Display Masked File Name
     *  @params name string employee name, type string document type, flag boolean to include timestamp
     * */
    public static getDownloadFileName(name, type, filename, flag = false) {
        const lastIndexExt = filename.lastIndexOf('.');
        const extension = filename.substr( lastIndexExt + 1);
        // if (flag) {
        //     const tstamp = filename.lastIndexOf('-');
        //     return `${name.split(' ').join('_')}_${type.split(' ').join('_')}_${filename.substring(tstamp + 1, lastIndexExt - 1)}.${extension}`;
        // }
        return `${name.split(' ').join('_')}_${type.split(' ').join('_')}.${extension}`;
    }
    /**
     *  Get CareersUrl for api requests
     * */
    public static get careersUrl(): string {
        return environment.careersUrl;
    }

}
