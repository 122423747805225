import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentsService} from "../../services/documents.service";
import {AlertService} from "../../services/alert.service";
import {Subscription} from "rxjs/index";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RequiredIf} from "../../validators/required-if.validator";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from 'file-saver';
import {EmployeeService} from "../../services/employee.service";
import {Router} from "@angular/router";
import * as _ from 'lodash';
import {AppConstants} from "../../../app.constants";
import {DomSanitizer} from "@angular/platform-browser";
@Component({
  selector: 'app-copy-documents',
  templateUrl: './copy-documents.component.html',
  styleUrls: ['./copy-documents.component.scss']
})
export class CopyDocumentsComponent implements OnInit, OnDestroy {

  constructor(
      public modal: NgbActiveModal,
      private docsService: DocumentsService,
      private alertService: AlertService,
      private fb: FormBuilder,
      private employeeService: EmployeeService,
      private router: Router,
      private sanitizer: DomSanitizer
  ) { }
  @Input() candidateId;
  @Input() employeeId;
  @Input() employeeName;
  @Input() documentsList = [];
  submitted = false;
  documentTypes = [];
  allSubscriptions = new Subscription();
  documentsForm = this.fb.group({});
  loading = false;
  checked = 0; uploadSuccess = 0;
  uploadedPercent = 0;
  okEvent = new EventEmitter();
  showCandidateProfile = false;
  candidateProfileImage = null;
  candidateProfileBlob = null;
  candidateProfileLoading = false;
  allSelect = 0;
  getDownloadFileName = AppConstants.getDownloadFileName;
  imageLoading = false;
  modalLoader = false;
  careersUrl = AppConstants.careersUrl;
  ngOnInit() {
    this.subscribeAlert();
    this.getDocumentTypes();
    console.log(`Employee Id:${this.employeeId}`);
    if (this.documentsList.length === 0) {
      this.getCandidateDocuments();
      // this.fetchCandidateProfileImage();
    }
  }
  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }
    /**
     * Subscribe to alerts service to display alerts
     * */
    subscribeAlert() {
        const sub = this.alertService.getAlert().subscribe((res) => {
            this.alertService.alertBar(res);
        });
        this.allSubscriptions.add(sub);
    }
    docTypeConditionallyRequired(formGroup) {
      if (formGroup.value.is_added) {
        return Validators.required(formGroup.get('doc_type')) ? {
          docTypeConditionallyRequired: true
        } : null;
      }
      return null;
    }
  /**
   * Fetch candidate documents list
   * */
  getCandidateDocuments() {
      this.modalLoader = true;
    this.docsService.fetchCandidateDocuments(this.candidateId).subscribe((result) => {
      if (result['success']) {
        this.documentsList = result ['data']['candidate_documents'];
        this.showCandidateProfile = result['data']['show_candidate_profile'];
        this.candidateProfileImage = result['data']['candidate_profile'];
        if (this.documentsList.length > 0) {
          this.documentsList.forEach((item) => {
              item.is_downloading = false;
              this.documentsForm.addControl(item['id'], this.fb.group({
                    'is_added': []
              }));
              (<FormGroup>this.documentsForm['controls'][item['id']]).addControl('doc_type', this.fb.control(null,
                  [RequiredIf(() => this.documentsForm['controls'][item['id']].get('is_added').value)]));
              (<FormGroup>this.documentsForm['controls'][item['id']]).addControl('doc_remark',
                  this.fb.control(item['document_remark'],
                      [RequiredIf(() => this.documentsForm['controls'][item['id']].get('is_added').value)]));
              this.documentsForm['controls'][item['id']].get('is_added').valueChanges.subscribe( value => {
                  this.documentsForm['controls'][item['id']].get('doc_type').updateValueAndValidity();
                  this.documentsForm['controls'][item['id']].get('doc_remark').updateValueAndValidity();
              });
          });
          // console.log(this.documentsForm);
        }
        this.modalLoader = false;
      }
    }, (error) => {
      this.alertService.error(error, false);
      this.modalLoader = false;
    });
  }
  /**
   * Fetch User Document Types
   * */
  getDocumentTypes() {
    this.docsService.fetchDocumentTypes().subscribe((result) => {
      if (result['success']) {
        this.documentTypes = result ['data'];
      }
    }, (error) => {
      this.alertService.error(error, false);
    });
  }
  /*
  *  Submit documents form
  * */
  submit() {
    this.submitted = true;
    this.loading = true;
    if (this.documentsForm.valid) {
        Object.keys(this.documentsForm.controls).forEach((item) => {
            if (this.documentsForm['controls'][item].get('is_added').value ) {
                const formData = {
                    candidateId: this.candidateId,
                    employeeId: this.employeeId,
                    doc_type: this.documentsForm['controls'][item].get('doc_type').value,
                    doc_remark: this.documentsForm['controls'][item].get('doc_remark').value
                };
                console.log(formData);
                let newObserver = this.employeeService.uploadFromCareers(item, formData)
                    .subscribe((result) => {
                        if (result['success']) {
                            this.uploadSuccess += 1;
                            this.uploadedPercent = Math.round((this.uploadSuccess / this.checked) * 100);
                        }
                    }, (error) => {
                        this.alertService.error(error, false);
                    });
            }
        });
      // console.log(this.documentsForm.value);
    } else {
        this.loading = false;
      // this.submitted = false;
      console.log(this.documentsForm.controls);
    }
  }
  /**
   * return form controls
   * */
  get form_error() {
    return this.documentsForm.controls;
  }
  /**
   *  Download document
   * */
  downloadDocument(document, profile = false) {
      let findIndex = -1
      if (!profile) {
           findIndex = _.findIndex(this.documentsList, ['id', document.id]);
          if (findIndex !== -1) {
              this.documentsList[findIndex].is_downloading = true;
          }
      }
      this.docsService.downloadCandidateFile({
            file_path: document['file_name'],
            candidate_id: this.candidateId,
            profile: profile
        }).subscribe((res: any) => {
            const blob = new Blob([res]);
            console.log(blob);
            // var url = window.URL.createObjectURL(blob, {oneTimeOnly: true});
           // console.log(url);
            // var image = new Image();
            // image.src = "data:image/jpg;blob," + blob;
            // var w = window.open("");
            // w.document.write(blob);
            // w.document.close();
            // w.document.write(image.outerHTML);
            // w.document.close();
            if (!profile) {
                if (findIndex !== -1) {
                    this.documentsList[findIndex].is_downloading = false;
                }
            }
            // let extension = document['file_name'].substr(document['file_name'].lastIndexOf('.'));
            // saveAs(blob, `${this.employeeName.split(' ').join('_')}_${document.document_type.split(' ').join('_')}.${extension}`);
            if (!profile) {
                saveAs(blob, AppConstants.getDownloadFileName(this.employeeName, document.document_type, document['file_name']));
            } else {
                saveAs(blob, AppConstants.getDownloadFileName(this.employeeName, 'Profile_Image', document['file_name']));
            }
        }, (error) => {
          this.alertService.error(error, false);
        });
    }
    // redirectToProfile() {
    //     this.router.navigate(['/manage/employee/profile/', this.addSuccessResponse['employeeId']]);
    // }
    /**
     * On is_added checked in docs form - add/subtract count by 1
     * */
    checkDocuments(event, docId, document_type) {
        console.log(event);
      if (event.target.checked) {
          this.documentsForm['controls'][docId].get('doc_remark').setValue(document_type);
         this.checked += 1;
      } else {
          this.documentsForm['controls'][docId].get('doc_remark').setValue(null);
          this.checked -= 1;
      }
      if (this.checked === this.documentsList.length) {
          this.allSelect = 1;
      } else {
          this.allSelect = 0;
      }
    }
    /**
     * select all inputs
     * */
    selectAllDocs(event) {
        if (event.target.checked) {
          this.allSelect = 1 ;
          Object.keys(this.documentsForm['controls']).forEach((item, index) => {
             this.documentsForm['controls'][item].get('is_added').setValue(1);
             this.documentsForm['controls'][item].get('doc_remark').setValue(this.documentsList[index].document_type);
          });
          this.checked = this.documentsList.length;
        } else {
            this.allSelect = 0 ;
            Object.keys(this.documentsForm['controls']).forEach((item, index) => {
                this.documentsForm['controls'][item].get('is_added').setValue(0);
                this.documentsForm['controls'][item].get('doc_remark').setValue(null);
            });
            this.checked = 0;
        }
    }
    /**
     *  Redirect to profile page
     * */
    redirectToProfile() {
      this.okEvent.emit({redirect: true, employeeId: this.employeeId});
        // this.router.navigate(['/manage/employee/profile/', this.employeeId]);
    }
    /**
     * Save profile image
     * */
    saveProfileImage() {
        this.candidateProfileLoading = true;
        this.docsService.uploadProfileCareers(this.candidateProfileImage, {
            employeeId: this.employeeId
        }).subscribe((res) => {
            if (res['success']) {
                if (res['data']['saved']) {
                    this.showCandidateProfile = false;
                    this.alertService.success('Profile image saved successfully', false);
                }
            }
        }, (error) => {
            this.alertService.error(error, false);
        });
    }
    fetchCandidateProfileImage() {
        this.imageLoading = true;
        this.docsService.downloadCandidateFile({
            file_path: 'na',
            candidate_id: this.candidateId,
            profile: true
        }).subscribe((res: any) => {
            const blob = new Blob([res]);
            const reader = new FileReader();
            console.log(reader);
            let base64data = null;
            reader.addEventListener("loadend", () => {
                base64data = reader.result;
                console.log(base64data);
                this.candidateProfileBlob = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64data);
                this.imageLoading = false;
            }, false);
            // reader.onloadend = function() {
            // this.candidateProfileBlob = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64data);
            reader.readAsDataURL(blob);
            console.log(base64data);
            //     console.log(reader.result);
            //     base64data = reader.result;
            // };
            // reader.onerror = function() {
            //     console.log(reader.error);
            // }
            // console.log(this.candidateProfileBlob);


        }, (error) => {
            this.alertService.error(error, false);
        });
    }
}
